import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useAuth } from "../AuthContext";
import axiosConfig from "../axiosConfig";
import { csrfToken } from "../csrfToken";
import "../css/Register.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Spinner } from "react-bootstrap"; // Import Spinner from react-bootstrap

const Register: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [backgroundImage, setBackgroundImage] = useState<string | null>(null);
  const [logo, setLogo] = useState<string | null>(null);
  const { isAuthenticated, login } = useAuth();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false); // New state for tracking submission
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }

    document.body.classList.add("register-page");

    return () => {
      document.body.classList.remove("register-page");
    };
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    setBackgroundImage("media/images/bg_register.png");
    setLogo("media/images/logo/PeerChess.png");
  }, []);

  const handleGoogleRegister = async () => {
    try {
      const redirectUri = process.env.REACT_APP_API_URL + "/auth/callback/";
      const googleRegisterUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=691607737462-s19tectj18tk24kdq97eqlgrepqkervc.apps.googleusercontent.com&redirect_uri=${encodeURIComponent(
        redirectUri
      )}&response_type=code&scope=email%20profile`;

      window.location.href = googleRegisterUrl;
    } catch (error) {
      console.error("Failed to initiate Google registration", error);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true); // Set isSubmitting to true when form is submitted

    try {
      const response = await axiosConfig.post(
        "/register/",
        {
          email,
          password,
        },
        {
          headers: {
            "X-CSRFToken": csrfToken,
          },
        }
      );

      if (response.data.token) {
        localStorage.setItem("token", response.data.token);
        login(response.data.token);
        navigate("/about-you");
      } else {
        setError("Registration failed. Please check the server response.");
        setIsSubmitting(false); // Reset isSubmitting in case of failure
      }
    } catch (error) {
      console.error("Registration failed:", error);
      setError("Registration failed. Please check your credentials.");
      setIsSubmitting(false); // Reset isSubmitting in case of failure
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div id="register-container" className="container">
      <div className="register-left">
        {logo && (
          <div className="logo-container">
            <Link to="/">
              <img
                src={`${process.env.REACT_APP_MEDIA_URL}/${logo}`}
                alt="Logo"
                className="logo-img-register"
              />
            </Link>
          </div>
        )}
        <h1>Create your account</h1>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              className="form-control"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              disabled={isSubmitting} // Disable inputs when submitting
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <div className="password-input-wrapper">
              <input
                type={showPassword ? "text" : "password"}
                className="form-control"
                id="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                disabled={isSubmitting} // Disable inputs when submitting
              />
              <FontAwesomeIcon
                icon={showPassword ? faEyeSlash : faEye}
                className="password-toggle-icon"
                onClick={togglePasswordVisibility}
              />
            </div>
          </div>
          <button
            type="submit"
            className="btn-register"
            disabled={isSubmitting}
          >
            {isSubmitting ? ( // Display spinner when submitting
              <>
                <Spinner animation="border" size="sm" className="me-2" />
                Signing Up...
              </>
            ) : (
              "SIGN UP"
            )}
          </button>
        </form>
        {error && <p className="text-danger mt-3">{error}</p>}
        <div className="social-register">
          <p>or</p>
          <button
            className="btn-google"
            onClick={handleGoogleRegister}
            disabled={isSubmitting} // Disable button when submitting
          >
            <img
              src="https://img.icons8.com/color/16/000000/google-logo.png"
              alt="Google"
            />
            Sign up with Google
          </button>
          <p>
            Already have an account?{" "}
            <Link to="/login" className="signin-link">
              Sign in here
            </Link>
          </p>
        </div>
      </div>
      <div
        className="register-right"
        style={{
          backgroundImage: `url(${process.env.REACT_APP_MEDIA_URL}/${backgroundImage})`,
        }}
      ></div>
    </div>
  );
};

export default Register;
