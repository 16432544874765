import React, { useState, useEffect } from "react";
import Select from "react-select";
import countryList from "react-select-country-list";

interface CountryOption {
  label: string;
  value: string;
}

const CountrySelector: React.FC<{
  onChange: (value: string) => void;
  defaultValue?: string;
}> = ({ onChange, defaultValue }) => {
  const options: CountryOption[] = countryList().getData();
  const [value, setValue] = useState<CountryOption | null>(
    defaultValue
      ? options.find((option) => option.value === defaultValue) || null
      : null
  );

  const handleChange = (selectedOption: CountryOption | null) => {
    setValue(selectedOption);
    if (selectedOption) {
      onChange(selectedOption.value);
    }
  };

  useEffect(() => {
    if (defaultValue) {
      setValue(options.find((option) => option.value === defaultValue) || null);
    }
  }, [defaultValue, options]);

  return <Select options={options} value={value} onChange={handleChange} />;
};

export default CountrySelector;
