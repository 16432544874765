import React, { useState, useEffect } from "react";
import axiosConfig from "../axiosConfig";
import { SparringRequest } from "../types";

const AllSparringsPage: React.FC = () => {
  const [sparrings, setSparrings] = useState<SparringRequest[]>([]);

  useEffect(() => {
    const fetchSparrings = async () => {
      try {
        const { data } = await axiosConfig.get<SparringRequest[]>(
          "/all-sparrings/"
        );
        setSparrings(data);
      } catch (error) {
        console.error("Error fetching sparrings:", error);
      }
    };

    fetchSparrings();
  }, []);

  return (
    <div style={{ padding: "20px" }}>
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <tr>
            <th>ID</th>
            <th>Offer User</th>
            <th>Requester</th>
            <th>Status</th>
            <th>Date</th>
            <th>Game Link</th>
          </tr>
        </thead>
        <tbody>
          {sparrings.map((sparring) => (
            <tr key={sparring.id}>
              <td>{sparring.id}</td>
              <td>{sparring.offer.user.username}</td>
              <td>{sparring.requester.username}</td>
              <td>{sparring.status}</td>
              <td>
                {sparring.selected_slot
                  ? `${new Date(sparring.selected_slot.start).toLocaleString()}`
                  : "N/A"}
              </td>
              <td>
                {sparring.game_link ? (
                  <a
                    href={sparring.game_link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Game
                  </a>
                ) : (
                  "No link"
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AllSparringsPage;
