import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../AuthContext";
import { ProtectedRouteProps } from "../types";

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element }) => {
  const { isAuthenticated, loading } = useAuth();
  const location = useLocation();

  // Si le chargement est en cours, on affiche quand même le contenu de la page
  if (loading) {
    // On peut afficher le contenu avec un spinner sur certaines sections, si nécessaire
    return (
      <>
        {element}
        {/* Tu peux ajouter un spinner en surcouche pour indiquer qu'une vérification est en cours */}
      </>
    );
  }

  // Une fois le chargement terminé et si l'utilisateur n'est pas authentifié
  if (!isAuthenticated) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  }

  return element;
};

export default ProtectedRoute;
