import React, { useState } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { useAuth } from "../AuthContext";
import RedeemPanel from "../components/RedeemPanel";
import toast from "react-hot-toast";
import "../css/CoinsInfo.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCoins,
  faMoneyBillWave,
  faGift,
} from "@fortawesome/free-solid-svg-icons";

const CoinsInfo: React.FC = () => {
  const { user, updateCoins } = useAuth(); // Inclure updateCoins ici
  const isPremium = user?.subscription_status === "premium";
  const [showPanel, setShowPanel] = useState(false); // State to control the panel visibility
  const [selectedReward, setSelectedReward] = useState<any>(null); // State to hold selected reward

  // Rename 'offers' to 'coinRewards' to clarify it's for coin-based rewards
  const coinRewards = [
    {
      description: "1 free class with a GM/IM",
      coinsRequired: 60,
      nonPremiumCoinsRequired: 100,
    },
    {
      description: "Access to exclusive sparring sessions",
      coinsRequired: 40,
      nonPremiumCoinsRequired: 80,
    },
    {
      description: "Video analysis of your games",
      coinsRequired: 30,
      nonPremiumCoinsRequired: 60,
    },
  ];

  const handleRedeemClick = (reward: any) => {
    setSelectedReward(reward); // Set the selected reward
    setShowPanel(true); // Open the confirmation panel
  };

  const handleClosePanel = () => {
    setShowPanel(false); // Close the panel
  };

  const confirmRedemption = async (email: string) => {
    if (selectedReward) {
      const coinsSpent = isPremium
        ? selectedReward.coinsRequired
        : selectedReward.nonPremiumCoinsRequired;

      updateCoins(-coinsSpent); // Mettez à jour les pièces ici
      toast.success(`Confirmation sent to ${email}`);
      setShowPanel(false);
    }
  };

  // Vérifier si `user` est null
  if (!user) {
    return <p>Loading...</p>; // Tu peux remplacer cela par un spinner de chargement si nécessaire
  }

  return (
    <Container className="coins-info-container">
      {/* Section: What are Coins? */}
      <Row className="text-center mb-5">
        <Col>
          <h1 className="coins-title">
            What are Coins?&nbsp;
            <FontAwesomeIcon icon={faCoins} />
          </h1>
          <p className="coins-description">
            Coins are a special currency you can earn on the platform and use to
            unlock rewards. You can earn coins by playing sparrings, especially
            against players with lower Elo. The coin system rewards effort and
            encourages challenging games.
          </p>
        </Col>
      </Row>
      <div className="divider" />
      <Row className="text-center mb-5">
        <Col>
          <h2 className="coins-subtitle">
            How to earn Coins? &nbsp;
            <FontAwesomeIcon icon={faMoneyBillWave} />
          </h2>
          <Row>
            <Col md={4}>
              <div className="coin-rule-card">
                <strong>Elo difference ≤ 100:</strong>
                <p>No coins are gained or lost.</p>
              </div>
            </Col>
            <Col md={4}>
              <div className="coin-rule-card">
                <strong>Elo difference between 100 and 200 points:</strong>
                <p>
                  I win <strong>2 coins</strong> if I have the higher Elo.
                </p>
              </div>
            </Col>
            <Col md={4}>
              <div className="coin-rule-card">
                <strong>Elo difference &gt; 200 points:</strong>
                <p>
                  I win <strong>5 coins</strong> if I have the higher Elo.
                </p>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="divider" />
      <Row className="text-center mb-4">
        <Col>
          <h1 className="coins-title">
            How to use my Coins?&nbsp; <FontAwesomeIcon icon={faGift} />
          </h1>
          <p className="coins-description">
            Unlock amazing benefits by using your coins! As a{" "}
            <strong>{isPremium ? "Premium" : "Free"}</strong> member, discover
            the rewards available to you below.
          </p>
        </Col>
      </Row>

      {/* Rewards List */}
      <Row className="rewards-container">
        {coinRewards.map((reward, index) => (
          <Col md={4} key={index} className="mb-5">
            <Card className="coins-card shadow-lg" style={{ padding: "20px" }}>
              <Card.Body className="text-center">
                <h4 className="reward-description">{reward.description}</h4>
                <p className="coins-required">
                  Requires{" "}
                  <span style={{ fontWeight: "bold", color: "#68201e" }}>
                    {isPremium
                      ? reward.coinsRequired
                      : reward.nonPremiumCoinsRequired}{" "}
                    coins
                  </span>
                </p>
                {!isPremium && (
                  <p
                    style={{
                      color: "#68201e",
                      fontSize: "0.9rem",
                      marginTop: "10px",
                    }}
                  >
                    Only{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {reward.coinsRequired} coins
                    </span>{" "}
                    for Premium members
                  </p>
                )}
                <Button
                  variant="primary"
                  className="use-coins-button" // Updated button class
                  disabled={
                    user.coins <
                    (isPremium
                      ? reward.coinsRequired
                      : reward.nonPremiumCoinsRequired)
                  }
                  onClick={() => handleRedeemClick(reward)} // Open the panel on click
                >
                  {user.coins >=
                  (isPremium
                    ? reward.coinsRequired
                    : reward.nonPremiumCoinsRequired)
                    ? "Redeem Now"
                    : "Not Enough Coins"}
                </Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>

      {/* Redeem Panel Component */}
      <RedeemPanel
        show={showPanel}
        handleClose={handleClosePanel}
        reward={selectedReward} // Renamed to reward
        isPremium={isPremium}
        confirmRedemption={confirmRedemption}
        userEmail={user.email}
      />
    </Container>
  );
};

export default CoinsInfo;
