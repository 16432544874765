import React, { useState, useEffect } from "react";
import axios from "../axiosConfig";
import { isAxiosError } from "../utils/axiosErrorUtils";
import { SparringOffer, SparringRequest, WeeklyVideo } from "../types";
import { useAuth } from "../AuthContext";
import { Modal, Button, Spinner } from "react-bootstrap";
import Select, { SingleValue } from "react-select";
import countryList from "react-select-country-list";
import Cookies from "js-cookie";
import { motion } from "framer-motion";
import "../css/ListSparringOffers.css";
import CountryFlag from "../components/CountryFlag";
import SkeletonLoader from "../components/SkeletonLoader";
import LoadingSpinner from "../components/LoadingSpinner";
import AvailabilityScheduler from "../components/AvailabilityScheduler";
import toast from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCoins,
  faBolt,
  faHandPointDown,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";

const ListSparringOffers: React.FC = () => {
  const [sparringOffers, setSparringOffers] = useState<SparringOffer[]>([]);
  const [weeklyVideo, setWeeklyVideo] = useState<WeeklyVideo | null>(null);
  const { isAuthenticated, user } = useAuth();
  const [showModal, setShowModal] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState<SparringOffer | null>(
    null
  );
  const [loading, setLoading] = useState(false);
  const [country, setCountry] = useState({ value: "All", label: "All" });
  const [eloRange, setEloRange] = useState<number>(150);
  const [sentRequests, setSentRequests] = useState<SparringRequest[]>([]);
  const [hasOfferedThisWeek, setHasOfferedThisWeek] = useState<boolean>(false);
  const [availabilityFilled, setAvailabilityFilled] = useState<boolean>(true);
  const [filterByAvailability, setFilterByAvailability] =
    useState<boolean>(false);
  const [showAvailabilityModal, setShowAvailabilityModal] = useState(false);

  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: "asc" | "desc";
  } | null>(null);
  const [videoLoading, setVideoLoading] = useState(true);
  const [offersLoading, setOffersLoading] = useState(true);
  const [imageLoading, setImageLoading] = useState(true);
  const [availabilitiesCopied, setAvailabilitiesCopied] = useState(false);

  const countries = [
    { value: "All", label: "All" },
    ...countryList().getData(),
  ];

  useEffect(() => {}, [videoLoading]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const offersResult = await axios.get(
          "/sparring-offers/list_sparring_offers/",
          {
            params: {
              filter_by_availability: filterByAvailability ? "true" : "false",
              country: country ? country.value : "All",
            },
          }
        );
        setSparringOffers(offersResult.data);
        setOffersLoading(false);

        const sparringRequestsResult = await axios.get("/my-sparrings/");
        const allRequests = [
          ...sparringRequestsResult.data.sent_pending_requests,
          ...sparringRequestsResult.data.accepted_requests,
          ...sparringRequestsResult.data.done_requests,
          ...sparringRequestsResult.data.pending_requests,
        ];
        setSentRequests(allRequests);

        const today = new Date();
        const dayOfWeek = today.getUTCDay();
        const diffToMonday = (dayOfWeek === 0 ? -6 : 1) - dayOfWeek;
        const currentWeekDate = new Date(
          Date.UTC(
            today.getUTCFullYear(),
            today.getUTCMonth(),
            today.getUTCDate() + diffToMonday
          )
        );
        const formattedWeek = currentWeekDate.toISOString().split("T")[0];

        const weeklyVideoResponse = await axios.get(
          `/weekly-videos/?week=${formattedWeek}`
        );
        setWeeklyVideo(weeklyVideoResponse.data[0]);

        const userOfferThisWeek = offersResult.data.some(
          (offer: SparringOffer) =>
            offer.user.id === user?.id &&
            offer.week?.id === weeklyVideoResponse.data[0].id
        );

        // Marquer l'offre dès que trouvée pour éviter l'effet des filtres
        setHasOfferedThisWeek(userOfferThisWeek);

        const availabilityResponse = await axios.get("/profile/availability");
        const availability = availabilityResponse.data;

        const currentWeekAvailability = availability.some((event: any) => {
          const start = new Date(event.start);
          return (
            start >= currentWeekDate &&
            start <
              new Date(currentWeekDate.getTime() + 7 * 24 * 60 * 60 * 1000)
          );
        });

        setAvailabilityFilled(currentWeekAvailability);
      } catch (error) {
        console.error("Error fetching data:", error);
        setAvailabilityFilled(false);
      }
    };

    if (isAuthenticated) {
      fetchData();
    }
  }, [isAuthenticated, filterByAvailability, eloRange, country]); // Ajoutez filterByAvailability ici

  const handleSort = (key: string) => {
    let direction: "asc" | "desc" = "asc";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "asc"
    ) {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const handleModalClose = async () => {
    setShowAvailabilityModal(false); // Ferme la modale

    const availabilityResponse = await axios.get("/profile/availability");
    const availability = availabilityResponse.data;

    const today = new Date();
    const dayOfWeek = today.getUTCDay();
    const diffToMonday = (dayOfWeek === 0 ? -6 : 1) - dayOfWeek;
    const currentWeekDate = new Date(
      Date.UTC(
        today.getUTCFullYear(),
        today.getUTCMonth(),
        today.getUTCDate() + diffToMonday
      )
    );

    // Vérifie les disponibilités de la semaine en cours
    const currentWeekAvailability = availability.some((event: any) => {
      const start = new Date(event.start);
      return (
        start >= currentWeekDate &&
        start < new Date(currentWeekDate.getTime() + 7 * 24 * 60 * 60 * 1000)
      );
    });

    // Mets à jour l'état availabilityFilled selon les résultats
    setAvailabilityFilled(currentWeekAvailability);

    // Réinitialise availabilitiesCopied après avoir fetch et mis à jour les données
    if (currentWeekAvailability) {
      setAvailabilitiesCopied(false);
    }
  };

  const handleCreateSparringOffer = async () => {
    // Vérifie si les disponibilités sont remplies avant d'autoriser la création
    if (!availabilityFilled && !availabilitiesCopied) {
      setShowAvailabilityModal(true);
      return; // Arrête ici si les disponibilités ne sont pas remplies
    }

    setLoading(true);
    try {
      const csrfToken = Cookies.get("csrftoken");

      const response = await axios.post(
        `/sparring-offers/create_offer/`,
        { week: weeklyVideo?.week },
        {
          headers: {
            "X-CSRFToken": csrfToken,
          },
        }
      );

      if (response.status === 201) {
        toast.success("Sparring offer created successfully!");
        const newOffer = response.data;
        setSparringOffers((prevOffers) => [...prevOffers, newOffer]);

        // Marquer comme ayant proposé un sparring cette semaine
        setHasOfferedThisWeek(true);
      }
    } catch (error) {
      console.error("Error creating sparring offer:", error);
      toast.error("Failed to create sparring offer.");
    } finally {
      setLoading(false);
    }
  };

  const handleCountryChange = (
    newValue: SingleValue<{ value: string; label: string }>
  ) => {
    if (newValue) {
      setCountry(newValue);
    }
  };

  const handleAvailabilityChange = () => {
    setFilterByAvailability((prev) => !prev);
  };

  const handleOfferSparring = async () => {
    console.log("selected offer : ", selectedOffer);
    if (!selectedOffer || !weeklyVideo) return;
    setLoading(true);
    try {
      const csrfToken = Cookies.get("csrftoken");

      await axios.post(
        `/sparring-requests/create_sparring_request/`,
        { offer: selectedOffer.id, week: weeklyVideo.id },
        {
          headers: {
            "X-CSRFToken": csrfToken,
          },
        }
      );

      setShowModal(false);
      setSentRequests((prev) => [
        ...prev,
        {
          id: Date.now(),
          offer: selectedOffer,
          requester: user,
          status: "pending",
          game_link: "",
          week: weeklyVideo,
        } as SparringRequest,
      ]);

      toast.success("Sparring request sent successfully!");
    } catch (error) {
      console.error("Error sending sparring request:", error);

      if (isAxiosError(error)) {
        // Spécifiez le type des données de la réponse
        const errorData = error.response?.data as { detail?: string };
        const errorMessage = errorData?.detail;

        if (
          errorMessage ===
          "You must fill in your availabilities from today onwards before sending a sparring request."
        ) {
          // Afficher le toast avec le message personnalisé
          toast.error("You need to fill your availabilities first !");
        } else if (errorMessage === "reverse_request_exists") {
          toast.error(
            "This user has already sent you a sparring request. Please respond to it first."
          );
        } else if (errorMessage === "already_requested_this_week") {
          toast.error(
            "You have already made a sparring request with this user this week."
          );
        } else {
          toast.error("Failed to send sparring request.");
        }
      } else {
        toast.error("An unexpected error occurred.");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleRowClick = (offer: SparringOffer) => {
    if (offer.user.id === user?.id) return;
    setSelectedOffer(offer);
    setShowModal(true);
  };

  const getProfilePhotoUrl = (photo: string | null) => {
    const baseUrl = process.env.REACT_APP_MEDIA_URL || "http://localhost:8000";

    if (photo) {
      // Ajoute "images/" devant le chemin si ce n'est pas déjà là
      if (photo.startsWith("http") || photo.startsWith("https")) {
        return photo;
      }
      return `${baseUrl}/${photo}`;
    }
    return `${baseUrl}/media/images/profile/default.png`;
  };

  const calculateSparringCost = (
    userElo: number,
    opponentElo: number
  ): { cost: string; color: string } => {
    const eloDifference = userElo - opponentElo;

    if (eloDifference >= -100 && eloDifference <= 100)
      return { cost: "0", color: "black" };
    if (eloDifference > 100 && eloDifference <= 200)
      return { cost: "+2", color: "green" };
    if (eloDifference < -100 && eloDifference >= -200)
      return { cost: "-2", color: "#C1272D" };
    if (eloDifference > 200) return { cost: "+5", color: "green" };
    return { cost: "-5", color: "#C1272D" };
  };

  // Maintenant, le filtre pour afficher les sparrings des autres utilisateurs
  const filteredOffers = sparringOffers.filter((offer) => {
    // Exclure l'offre de l'utilisateur actuel
    if (offer.user.id === user?.id) {
      return false;
    }

    // Filtrer les sparrings selon le pays sélectionné
    const matchesCountry =
      country?.value === "All" || offer.user.country === country?.value;

    // Filtrer selon la plage Elo
    const inEloRange =
      offer.user.elo >= (user?.elo || 0) - eloRange &&
      offer.user.elo <= (user?.elo || 0) + eloRange;

    // Vérifier si l'offre a déjà une demande en attente
    const hasExcludedStatus = sentRequests.some(
      (req) =>
        (req.offer.user.id === offer.user.id ||
          req.requester.id === offer.user.id) &&
        (req.status === "pending" || req.status === "accepted")
    );
    // Retourner les offres qui correspondent aux critères
    return matchesCountry && inEloRange && !hasExcludedStatus;
  });

  const sortedOffers = [...filteredOffers].sort((a, b) => {
    if (a.user.id === user?.id) return -1;
    if (b.user.id === user?.id) return 1;

    if (sortConfig !== null) {
      let aValue: any;
      let bValue: any;

      if (sortConfig.key === "cost") {
        const aCost = calculateSparringCost(user?.elo || 0, a.user.elo).cost;
        const bCost = calculateSparringCost(user?.elo || 0, b.user.elo).cost;
        aValue = parseInt(aCost.replace("+", "").replace("-", ""), 10);
        bValue = parseInt(bCost.replace("+", "").replace("-", ""), 10);
      } else {
        aValue = a[sortConfig.key as keyof SparringOffer] || "";
        bValue = b[sortConfig.key as keyof SparringOffer] || "";
      }

      if (aValue < bValue) {
        return sortConfig.direction === "asc" ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === "asc" ? 1 : -1;
      }
    }
    return 0;
  });

  return (
    <div className="sparring-offers-section">
      <div className="container-fluid mt-5">
        <motion.div
          className="row mb-4"
          initial="hidden"
          animate="visible"
          variants={{
            hidden: { opacity: 0, y: 20 },
            visible: { opacity: 1, y: 0 },
          }}
          transition={{ duration: 0.5 }}
        >
          <div className="col-md-6 text-center">
            <h2>Introduction Video</h2>
            {/* Continue de gérer le chargement et l'affichage du contenu */}
            {videoLoading && (
              <div
                style={{
                  position: "relative",
                  height: "300px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#f8ede3",
                }}
              >
                <LoadingSpinner
                  size="sm"
                  spinnerColor="#8d493a"
                  backgroundColor="#f8ede3"
                />
              </div>
            )}

            {weeklyVideo && (
              <div
                style={{
                  position: "relative",
                  paddingBottom: "56.25%",
                  height: 0,
                  overflow: "hidden",
                  maxWidth: "100%",
                  marginBottom: "10px",
                  display: videoLoading ? "none" : "block",
                }}
              >
                <iframe
                  src={weeklyVideo.hint_video || ""}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                  }}
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                  title="Hint Video"
                  onLoad={() => setVideoLoading(false)}
                ></iframe>
              </div>
            )}
          </div>

          <div className="col-md-6">
            <h2>Position of the week</h2>
            {imageLoading && (
              <div
                style={{
                  position: "relative",
                  height: "300px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#f8f9fa",
                }}
              >
                <LoadingSpinner
                  size="sm"
                  spinnerColor="#8d493a"
                  backgroundColor="#f8ede3"
                />
              </div>
            )}

            {weeklyVideo && (
              <div
                style={{
                  position: "relative",
                  paddingBottom: "56.25%",
                  height: 0,
                  overflow: "hidden",
                  maxWidth: "100%",
                  marginBottom: "10px",
                  display: imageLoading ? "none" : "block",
                }}
              >
                <a
                  href={weeklyVideo.lichess_link || "#"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={`${
                      process.env.REACT_APP_MEDIA_URL || "http://localhost:8000"
                    }/media/images/${weeklyVideo.position_of_the_week}`}
                    alt="Chess Position"
                    className="img-fluid"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                    onLoad={() => setImageLoading(false)}
                  />
                </a>
              </div>
            )}
          </div>
        </motion.div>

        <div className="offers-container p-4">
          {hasOfferedThisWeek ? (
            <div className="incentive-section text-center mb-4">
              <h3 className="text-center incentive-text">
                Find your sparring here{" "}
                <FontAwesomeIcon
                  icon={faHandPointDown}
                  style={{ marginLeft: "5px" }}
                />
              </h3>
            </div>
          ) : (
            <div className="incentive-section text-center mb-4">
              <h3 className="text-center incentive-text">
                Propose your first sparring offer and earn 1 coin!
              </h3>
              <Button
                variant="warning"
                size="lg"
                onClick={handleCreateSparringOffer}
                disabled={loading}
                className="mt-3"
              >
                {loading ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  <>
                    Propose an Offer{" "}
                    <FontAwesomeIcon
                      icon={faBolt}
                      style={{ marginLeft: "5px" }}
                    />
                  </>
                )}
              </Button>
            </div>
          )}
          <div className="filters-container">
            <h3 className="filters-title">Filters</h3>
            <div className="filters d-flex align-items-center">
              <div
                className="filter-item elo-range-container"
                style={{ marginRight: "10px", marginTop: "20px" }}
              >
                <div className="range-container">
                  <input
                    id="eloRange"
                    type="range"
                    min="50"
                    max="500"
                    step="50"
                    value={eloRange}
                    onChange={(e) => setEloRange(Number(e.target.value))}
                  />
                  <div className="range-label-left">{-eloRange}</div>
                  <div className="range-label-right">{eloRange}</div>
                </div>
                <div className="elo-range-label">
                  Elo Range: {user?.elo - eloRange} - {user?.elo + eloRange}
                </div>
              </div>
              <div
                className="filter-item availability-container"
                style={{ marginRight: "10px" }}
              >
                <Select
                  options={countries}
                  value={country}
                  onChange={handleCountryChange}
                  placeholder="Country"
                  className="react-select-container"
                  classNamePrefix="react-select"
                />
                <label className="toggle-switch">
                  <input
                    type="checkbox"
                    checked={filterByAvailability}
                    onChange={handleAvailabilityChange}
                  />
                  <span className="slider"></span>
                  Match my availability
                </label>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-12">
              <div className="table-responsive">
                <motion.table
                  className="table table-centered"
                  initial="hidden"
                  animate="visible"
                  variants={{
                    hidden: { opacity: 0, y: 20 },
                    visible: { opacity: 1, y: 0 },
                  }}
                  transition={{ duration: 0.5 }}
                >
                  <thead>
                    <tr>
                      <th onClick={() => handleSort("user.country")}>
                        Country
                      </th>
                      <th onClick={() => handleSort("user.username")}>
                        Lichess username
                      </th>
                      <th onClick={() => handleSort("user.elo")}>FIDE Elo</th>
                      <th onClick={() => handleSort("cost")}>Cost</th>
                    </tr>
                  </thead>
                  <tbody>
                    {offersLoading
                      ? Array.from({ length: 5 }).map((_, index) => (
                          <SkeletonLoader key={index} height="50px" />
                        ))
                      : sortedOffers.map((offer) => {
                          const { cost, color } = calculateSparringCost(
                            user?.elo || 0,
                            offer.user.elo
                          );

                          return (
                            <motion.tr
                              key={offer.id}
                              onClick={() => handleRowClick(offer)}
                              className={
                                offer.user.id === user?.id
                                  ? "own-offer-row non-clickable"
                                  : "clickable-row"
                              }
                              initial="hidden"
                              animate="visible"
                              variants={{
                                hidden: { opacity: 0, y: 20 },
                                visible: { opacity: 1, y: 0 },
                              }}
                              transition={{ duration: 0.5, delay: 0.1 }}
                            >
                              <td className="text-center">
                                <CountryFlag countryCode={offer.user.country} />
                              </td>

                              <td className="text-center">
                                {offer.user.username}
                                {offer.user.is_elo_verified && (
                                  <FontAwesomeIcon
                                    icon={faCheckCircle}
                                    style={{ color: "blue", marginLeft: "8px" }}
                                    title="Elo Verified"
                                  />
                                )}
                              </td>

                              <td className="text-center">{offer.user.elo}</td>

                              <td className="text-center" style={{ color }}>
                                {cost} <FontAwesomeIcon icon={faCoins} />
                              </td>
                            </motion.tr>
                          );
                        })}
                  </tbody>
                </motion.table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        dialogClassName="custom-modal-width"
      >
        <Modal.Header closeButton>
          <Modal.Title>Propose a Sparring</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedOffer && (
            <div className="modal-body-content">
              <div className="modal-left">
                <img
                  src={getProfilePhotoUrl(selectedOffer.user.photo)}
                  alt="User Profile"
                  className="profile-photo-modal"
                />
              </div>
              <div className="modal-right">
                <h2 className="mb-2">
                  {selectedOffer.user.first_name} {selectedOffer.user.last_name}{" "}
                  {selectedOffer.user.is_elo_verified && (
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      style={{
                        color: "blue",
                        marginLeft: "8px",
                        fontSize: "1.5rem",
                      }}
                      title="Elo Verified"
                    />
                  )}
                </h2>
                <p
                  style={{
                    fontSize: "0.9rem",
                    color: "#8d493a",
                    marginTop: "-5px",
                    textAlign: "center",
                  }}
                >
                  ({selectedOffer.user.elo} ELO)
                </p>
                <p>
                  <em>About me: {selectedOffer.user.about_me}</em>
                </p>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleOfferSparring}
            disabled={loading}
          >
            {loading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              "Offer a Sparring!"
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showAvailabilityModal}
        onHide={handleModalClose}
        centered
        dialogClassName="modal-xl" // Transformer la modale en grande modale
      >
        <Modal.Header closeButton>
          <Modal.Title>Set Your Availability</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Remplacer le contenu de la modale par le AvailabilityScheduler */}
          <AvailabilityScheduler
            onCopyLastWeek={() => {
              console.log("Last week's availabilities copied");
              setAvailabilitiesCopied(true);
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowAvailabilityModal(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ListSparringOffers;
