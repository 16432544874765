import React, { useState, useEffect } from "react";
import { Form, Button, Container, Row, Col, Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axiosConfig from "../axiosConfig";
import "../css/PasswordResetRequest.css";
import FloatingChessPieces from "../components/FloatingChessPieces";

const PasswordResetRequest: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [error, setError] = useState<string>("");
  const navigate = useNavigate();

  useEffect(() => {
    document.body.style.overflow = "hidden"; // Applique overflow hidden quand la page est montée

    return () => {
      document.body.style.overflow = "auto"; // Rétablit le scroll normal quand la page est quittée
    };
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setMessage("");
    setError("");

    try {
      const response = await axiosConfig.post("/reset-password/", { email });

      if (response.status === 200) {
        setMessage("Password reset email has been sent.");
        setTimeout(() => {
          navigate("/login");
        }, 3000);
      } else {
        setError("Failed to send password reset email.");
      }
    } catch (error) {
      console.error("Error sending password reset email:", error);
      setError("Failed to send password reset email. Please try again.");
    }
  };

  return (
    <div className="full-page-container">
      <FloatingChessPieces /> {/* Ajouter les pièces d'échecs flottantes */}
      <Container>
        <Row className="justify-content-center">
          <Col md={6}>
            <h1 className="text-center mb-4">Reset Your Password</h1>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formEmail" className="mb-3">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </Form.Group>
              <Button variant="primary" type="submit" className="w-100">
                Send Reset Link
              </Button>
            </Form>
            {message && (
              <Alert variant="success" className="mt-3">
                {message}
              </Alert>
            )}
            {error && (
              <Alert variant="danger" className="mt-3">
                {error}
              </Alert>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PasswordResetRequest;
