import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

interface SkeletonLoaderProps {
  height?: string;
  width?: string;
}

const SkeletonLoader: React.FC<SkeletonLoaderProps> = ({ height, width }) => {
  return (
    <tr style={{ height: height, width: width }}>
      <td>
        <Skeleton circle={true} height={50} width={50} />
      </td>
      <td>
        <Skeleton width={50} />
      </td>
      <td>
        <Skeleton width={120} />
      </td>
      <td>
        <Skeleton width={60} />
      </td>
      <td>
        <Skeleton width={150} />
      </td>
      <td>
        <Skeleton width={60} />
      </td>
    </tr>
  );
};

export default SkeletonLoader;
