import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext";

const RegisterGoogleSuccess: React.FC = () => {
  const navigate = useNavigate();
  const { login } = useAuth();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");

    if (token) {
      console.log("Token found:", token);
      login(token);
      navigate("/about-you");
    } else {
      console.error("No token found in URL");
      navigate("/login");
    }
  }, [login, navigate]);

  return (
    <div>
      <p>Loading...</p>
    </div>
  );
};

export default RegisterGoogleSuccess;
