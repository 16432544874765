import React, { useState } from "react";
import { Button, Offcanvas, Form } from "react-bootstrap";
import axios from "../axiosConfig";
import "../css/RedeemPanel.css";
import { RedeemPanelProps } from "../types";

const RedeemPanel: React.FC<RedeemPanelProps> = ({
  show,
  handleClose,
  reward,
  isPremium,
  confirmRedemption,
  userEmail,
}) => {
  const [checked, setChecked] = useState(false);
  const [selectedOption, setSelectedOption] = useState<number | null>(null); // Store the code instead of a string
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleConfirm = async () => {
    if (checked && selectedOption !== null) {
      setIsSubmitting(true);
      try {
        const coinsSpent = isPremium
          ? reward.coinsRequired
          : reward.nonPremiumCoinsRequired;

        // Make the API call to redeem the offer
        const response = await axios.post("/redeem-offer/", {
          email: userEmail,
          reward: reward.description,
          coinsSpent,
          selectedOption,
        });

        if (response.status === 200) {
          confirmRedemption(userEmail, coinsSpent);
        }
      } catch (error) {
        console.error("Error redeeming reward:", error);
      } finally {
        setIsSubmitting(false);
        handleClose();
      }
    }
  };

  // Calculate savings
  const savings = reward
    ? reward.nonPremiumCoinsRequired - reward.coinsRequired
    : 0;

  return (
    <Offcanvas show={show} onHide={handleClose} placement="end">
      <Offcanvas.Header closeButton>
        <h2 className="confirm-title">Confirm Your Choice</h2>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {reward && (
          <>
            <h4 className="selected-reward">{reward.description}</h4>
            <p>
              You are about to redeem{" "}
              <strong>
                {isPremium
                  ? reward.coinsRequired
                  : reward.nonPremiumCoinsRequired}{" "}
                coins
              </strong>
              .
            </p>
            {isPremium && (
              <p className="savings-message">
                You save <strong>{savings} coins</strong> with the premium
                subscription!
              </p>
            )}

            <Form.Group controlId="responsibilityCheckbox">
              <Form.Check
                type="checkbox"
                label="I certify that I have not engaged in fake sparrings to obtain coins, under penalty of permanent account closure."
                checked={checked}
                onChange={(e) => setChecked(e.target.checked)}
              />
            </Form.Group>
            <div className="divider" />
            {/* Custom Questions Based on Reward */}
            {reward.description === "1 free class with a GM/IM" && (
              <Form.Group controlId="lessonOptions" className="mt-4">
                <Form.Label>
                  What would you like to focus on during the class?
                </Form.Label>
                <Form.Check
                  type="radio"
                  label="Review sparring games"
                  name="lessonOption"
                  onChange={() => setSelectedOption(0)} // Code for this option
                />
                <Form.Check
                  type="radio"
                  label="Help with opening repertoire"
                  name="lessonOption"
                  onChange={() => setSelectedOption(1)} // Code for this option
                />
                <Form.Check
                  type="radio"
                  label="Work on strategy"
                  name="lessonOption"
                  onChange={() => setSelectedOption(2)} // Code for this option
                />
                <Form.Check
                  type="radio"
                  label="Endgames"
                  name="lessonOption"
                  onChange={() => setSelectedOption(3)} // Code for this option
                />
                <Form.Check
                  type="radio"
                  label="At the professor's discretion"
                  name="lessonOption"
                  onChange={() => setSelectedOption(4)} // Code for this option
                />
              </Form.Group>
            )}
            {/* Additional Questions for Other Rewards */}
            {reward.description === "Access to exclusive sparring sessions" && (
              <Form.Group controlId="sparringOptions" className="mt-4">
                <Form.Label>
                  What type of sparring sessions are you interested in?
                </Form.Label>
                <Form.Check
                  type="radio"
                  label="Blitz Sparring"
                  name="sparringOption"
                  onChange={() => setSelectedOption(5)} // Code for this option
                />
                <Form.Check
                  type="radio"
                  label="Rapid Sparring"
                  name="sparringOption"
                  onChange={() => setSelectedOption(6)} // Code for this option
                />
                <Form.Check
                  type="radio"
                  label="Classical Sparring"
                  name="sparringOption"
                  onChange={() => setSelectedOption(7)} // Code for this option
                />
              </Form.Group>
            )}
            <Button
              variant="success"
              className="confirm-button" // Make sure to apply the confirm-button class
              onClick={handleConfirm}
              disabled={!checked || selectedOption === null || isSubmitting}
            >
              {isSubmitting ? "Processing..." : "Confirm"}
            </Button>
          </>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default RedeemPanel;
