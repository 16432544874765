import axios from "axios";
import { getCookie } from "./csrfToken";

const axiosConfig = axios.create({
  baseURL: process.env.REACT_APP_API_URL || "http://localhost:8000/api/",
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    "X-CSRFToken": getCookie("csrftoken"),
  },
});

axiosConfig.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  const csrfToken = getCookie("csrftoken");

  if (!csrfToken) {
    console.error("CSRF token is missing.");
  }

  if (csrfToken) {
    config.headers["X-CSRFToken"] = csrfToken;
  }
  if (token) {
    config.headers.Authorization = `Token ${token}`;
  }
  return config;
});

export default axiosConfig;
