import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext";
import "../css/Header.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoins, faBars, faCrown } from "@fortawesome/free-solid-svg-icons";

const Header: React.FC = () => {
  const { isAuthenticated, user, logout, refetchProfile, coins } = useAuth();
  const navigate = useNavigate();
  const mediaUrl = process.env.REACT_APP_MEDIA_URL || "http://localhost:8000";

  const logoUrl = `${mediaUrl.replace(
    /\/+$/,
    ""
  )}/media/images/logo/PeerChess_new.png`;

  useEffect(() => {
    if (!user && isAuthenticated) {
      refetchProfile();
    }
  }, [user, isAuthenticated, refetchProfile]);

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  return (
    <nav className="navbar navbar-expand-lg">
      <div className="container">
        <Link className="navbar-brand" to="/">
          <img src={logoUrl} alt="PeerChess" className="logo" />
        </Link>

        <button
          className="btn btn-find-sparring d-none d-lg-block"
          onClick={() => navigate("/dashboard")}
        >
          Find a sparring!
        </button>

        <button
          className="navbar-toggler d-lg-none"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <FontAwesomeIcon icon={faBars} />
        </button>

        <div
          className="collapse navbar-collapse d-lg-none"
          id="navbarNavDropdown"
        >
          <ul className="navbar-nav me-auto">
            <li className="nav-item">
              <Link className="nav-link" to="/dashboard">
                Find a sparring
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/my-sparrings">
                My Sparrings
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/weekly-summaries">
                My Reviews
              </Link>
            </li>
          </ul>
        </div>

        <div className="ml-auto d-flex align-items-center">
          {isAuthenticated && user && (
            <>
              <div className="coins-display mr-3 d-flex align-items-center">
                <Link
                  to="/coins-info"
                  className="coins-link"
                  style={{
                    color: "#8d493a",
                    fontWeight: "bold",
                    display: "flex",
                    alignItems: "center",
                    textDecoration: "none",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faCoins}
                    style={{ color: "#8d493a", marginRight: "5px" }}
                    className="coins-icon"
                  />
                  <span
                    style={{
                      color: "#8d493a",
                      fontWeight: "bold",
                      marginRight: "10px",
                    }}
                  >
                    {coins}
                  </span>
                </Link>
              </div>
              <div className="dropdown">
                <button
                  className="btn btn-secondary dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {user.subscription_status === "premium" && (
                    <FontAwesomeIcon
                      icon={faCrown}
                      style={{ marginRight: "10px" }}
                    />
                  )}
                  {user.username}
                </button>
                <ul
                  className="dropdown-menu dropdown-menu-right"
                  aria-labelledby="dropdownMenuButton"
                >
                  <li>
                    <Link className="dropdown-item" to="/profile">
                      Profile
                    </Link>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <button className="dropdown-item" onClick={handleLogout}>
                      Log out
                    </button>
                  </li>
                </ul>
              </div>
            </>
          )}
          {!isAuthenticated && (
            <>
              <button
                className="btn btn-outline-light"
                onClick={() => navigate("/login")}
              >
                Sign in
              </button>
              <button
                className="btn btn-primary"
                onClick={() => navigate("/register")}
              >
                Sign up for free!
              </button>
            </>
          )}
          {isAuthenticated && user && user.subscription_status === "free" && (
            <button
              className="btn btn-premium-header ml-2"
              onClick={() => navigate("/premium")}
            >
              <FontAwesomeIcon icon={faCrown} />
            </button>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Header;
