import React from "react";

const RegistrationSuccess: React.FC = () => {
  return (
    <div
      style={{
        backgroundColor: "#f8ede3",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h2>Registration successful. Check your email.</h2>
    </div>
  );
};

export default RegistrationSuccess;
