import React, { useState, useEffect } from "react";
import axios from "../axiosConfig";
import { SparringRequest } from "../types";
import "../css/MySparrings.css";
import CountryFlag from "../components/CountryFlag";
import SkeletonLoader from "../components/SkeletonLoader";
import toast from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChessKing,
  faCheckCircle,
  faHourglassHalf,
  faArrowUp,
  faClock,
  faCalendarCheck,
  faArrowDown,
  faThumbtack,
  faCheck,
  faTimes,
  faHourglass,
  faTimesCircle,
  faHandPointer,
  faLink,
} from "@fortawesome/free-solid-svg-icons";
import { Modal, Button } from "react-bootstrap";
import { useAuth } from "../AuthContext";

const formatDate = (dateString: string | null | undefined) => {
  if (!dateString || isNaN(Date.parse(dateString))) return "Date : to define";
  const date = new Date(dateString);
  return date.toLocaleString("en-GB", {
    day: "numeric",
    month: "long",
    hour: "2-digit",
    minute: "2-digit",
    timeZoneName: "short",
  });
};

// Format the time (hours and minutes) for display
const formatTime = (dateString: string | null | undefined) => {
  if (!dateString || isNaN(Date.parse(dateString))) return "Date : to define";
  const date = new Date(dateString);
  return date.toLocaleTimeString("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
    timeZoneName: "short",
  });
};

const MySparrings: React.FC = () => {
  const [toBeScheduledRequests, setToBeScheduledRequests] = useState<
    SparringRequest[]
  >([]);
  const [scheduledRequests, setScheduledRequests] = useState<SparringRequest[]>(
    []
  );
  const [doneRequests, setDoneRequests] = useState<SparringRequest[]>([]);
  const [pendingRequests, setPendingRequests] = useState<SparringRequest[]>([]);
  const [sentPendingRequests, setSentPendingRequests] = useState<
    SparringRequest[]
  >([]);
  const [recentSparrings, setRecentSparrings] = useState<SparringRequest[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedRequest, setSelectedRequest] =
    useState<SparringRequest | null>(null);
  const [proposedSlots, setProposedSlots] = useState<
    { id: string; start: string; end: string }[]
  >([]);
  const [selectedSlots, setSelectedSlots] = useState<string[]>([]);
  const [scoreInput, setScoreInput] = useState({
    player1Score: "",
    player2Score: "",
  });
  const [gameLinkInput, setGameLinkInput] = useState("");

  const { user } = useAuth();
  const currentUserId = user?.id || null;
  const [showProposeSlotsModal, setShowProposeSlotsModal] = useState(false);
  const [showConfirmSlotModal, setShowConfirmSlotModal] = useState(false);
  const [overlappingSlots, setOverlappingSlots] = useState<
    { id: string; start: string; end: string }[]
  >([]);
  const [loading, setLoading] = useState(true);

  const onRequestUpdate = (updatedRequest: SparringRequest) => {
    // Update the appropriate request list
    setScheduledRequests((prevRequests: SparringRequest[]) =>
      prevRequests.map((request: SparringRequest) =>
        request.id === updatedRequest.id ? updatedRequest : request
      )
    );
  };

  useEffect(() => {
    if (selectedRequest) {
      if (
        selectedRequest.status === "accepted" &&
        currentUserId === selectedRequest.offer.user.id &&
        !selectedRequest.selected_slot &&
        (!selectedRequest.proposed_slots ||
          selectedRequest.proposed_slots.length === 0)
      ) {
        fetchOverlappingSlots(selectedRequest);
      }
    }
  }, [selectedRequest]);

  useEffect(() => {
    if (showConfirmSlotModal) {
      fetchProposedSlots();
    }
  }, [showConfirmSlotModal]);

  useEffect(() => {
    if (selectedRequest) {
      setProposedSlots([]);
      setSelectedSlots([]);
    }
  }, [selectedRequest]);

  useEffect(() => {
    if (showProposeSlotsModal || showConfirmSlotModal) {
      setSelectedSlots([]);
    }
  }, [showProposeSlotsModal, showConfirmSlotModal]);

  // Fetch sparring requests on mount
  useEffect(() => {
    const fetchSparringRequests = async () => {
      try {
        setLoading(true);
        const result = await axios.get("/my-sparrings/");
        const now = new Date();

        const acceptedRequests = result.data.accepted_requests;

        // Séparer les sparrings acceptés en ceux qui sont à planifier (to be scheduled) et ceux qui sont planifiés (scheduled)
        const toBeScheduled = acceptedRequests.filter(
          (request: SparringRequest) => !request.selected_slot
        );

        const scheduled = acceptedRequests.filter(
          (request: SparringRequest) =>
            request.selected_slot && new Date(request.selected_slot.end) >= now
        );

        // Check if any scheduled requests have passed and move them to doneRequests
        const newlyDoneRequests = acceptedRequests.filter(
          (request: SparringRequest) =>
            request.selected_slot && new Date(request.selected_slot.end) < now
        );

        // Update the status of newly done requests to "done"
        const updatedNewlyDoneRequests = newlyDoneRequests.map(
          (request: SparringRequest) => ({
            ...request,
            status: "done",
          })
        );

        const doneRequests = [
          ...result.data.done_requests,
          ...updatedNewlyDoneRequests,
        ];
        const recentSparrings = result.data.recent_sparrings;
        console.log("recent", recentSparrings);

        setToBeScheduledRequests(toBeScheduled);
        setScheduledRequests(scheduled);
        setDoneRequests(doneRequests);
        setPendingRequests(result.data.pending_requests);
        setSentPendingRequests(result.data.sent_pending_requests);
        setRecentSparrings(recentSparrings);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching sparring requests:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSparringRequests();
  }, []);

  useEffect(() => {
    const now = new Date();

    // Déplacer les sparrings planifiés qui sont passés dans "Done Sparrings"
    const newlyDoneRequests = scheduledRequests.filter(
      (request) =>
        request.selected_slot && new Date(request.selected_slot.end) < now
    );

    if (newlyDoneRequests.length > 0) {
      setScheduledRequests((prevScheduled) =>
        prevScheduled.filter(
          (request) => !newlyDoneRequests.find((done) => done.id === request.id)
        )
      );

      setDoneRequests((prevDone) => [...prevDone, ...newlyDoneRequests]);
    }
  }, [scheduledRequests]);

  const fetchProposedSlots = async () => {
    if (!selectedRequest) {
      console.error(
        "No selected request, fetchProposedSlots will not proceed."
      );
      return;
    }

    try {
      const result = await axios.get(
        `/sparring-requests/${selectedRequest.id}/retrieve_proposed_slots/`
      );

      const proposedSlots = result.data.proposed_slots;

      if (!proposedSlots || proposedSlots.length === 0) {
        console.error("No proposed slots found");
        return;
      }

      setProposedSlots(proposedSlots);
    } catch (error) {
      console.error("Error fetching proposed slots:", error);
    }
  };

  const fetchOverlappingSlots = async (request: SparringRequest) => {
    if (!request) {
      console.error(
        "No selected request, fetchOverlappingSlots will not proceed."
      );
      return;
    }

    try {
      const result = await axios.get(
        `/sparring-requests/${request.id}/get_availabilities/`
      );

      const overlappingSlots = result.data.overlapping_slots;

      if (!overlappingSlots || overlappingSlots.length === 0) {
        console.error("No overlapping slots found");
        return;
      }

      setOverlappingSlots(overlappingSlots);
      console.log("here");
      setShowProposeSlotsModal(true);
    } catch (error) {
      console.error("Error fetching overlapping slots:", error);
    }
  };

  const handleRowClick = (request: SparringRequest) => {
    setSelectedRequest(request);
    setScoreInput({
      player1Score:
        request.requester.id === currentUserId
          ? String(request.requester_score ?? "")
          : String(request.offer_score ?? ""),
      player2Score:
        request.requester.id !== currentUserId
          ? String(request.requester_score ?? "")
          : String(request.offer_score ?? ""),
    });
    setGameLinkInput(request.game_link || "");

    if (
      request.status === "accepted" &&
      currentUserId === request.requester.id
    ) {
      if (!request.selected_slot) {
        if (!request.proposed_slots || request.proposed_slots.length === 0) {
          // Définir showModal à true pour afficher la modale avec le message
          setShowModal(true);
        } else {
          setShowConfirmSlotModal(true);
        }
      } else {
        setShowModal(true);
      }
    } else if (
      request.status === "accepted" &&
      currentUserId === request.offer.user.id
    ) {
      if (request.selected_slot) {
        setShowModal(true);
      } else {
        if (request.proposed_slots && request.proposed_slots.length > 0) {
          setShowModal(true);
        } else {
          setShowProposeSlotsModal(true);
        }
      }
    } else {
      setShowModal(true);
    }
  };

  const handleRecentSparringClick = (sparring: SparringRequest) => {
    setSelectedRequest(sparring);
    setScoreInput({
      player1Score:
        sparring.requester.id === currentUserId
          ? String(sparring.requester_score ?? "")
          : String(sparring.offer_score ?? ""),
      player2Score:
        sparring.requester.id !== currentUserId
          ? String(sparring.requester_score ?? "")
          : String(sparring.offer_score ?? ""),
    });
    setGameLinkInput(sparring.game_link || "");
    setShowModal(true);
  };

  const handleSave = async () => {
    if (selectedRequest) {
      try {
        // Determine which user's scores and review status to update
        const isRequester = selectedRequest.requester.id === currentUserId;
        const updatedRequest: SparringRequest = {
          ...selectedRequest,
          game_link: gameLinkInput,
          requester_score: isRequester
            ? Number(scoreInput.player1Score)
            : Number(scoreInput.player2Score),
          offer_score: isRequester
            ? Number(scoreInput.player2Score)
            : Number(scoreInput.player1Score),
          status: "done", // Update status to "done"
          review_status:
            user?.subscription_status === "premium"
              ? "in_progress"
              : "not_started",
        };

        await axios.post(
          `/sparring-requests/${selectedRequest.id}/update-game-link/`,
          {
            game_link: updatedRequest.game_link,
            requester_score: updatedRequest.requester_score,
            offer_score: updatedRequest.offer_score,
            status: updatedRequest.status, // Send status update
            review_status: updatedRequest.review_status,
          }
        );

        setSelectedRequest(updatedRequest);

        // Update doneRequests list
        setDoneRequests((prevRequests) =>
          prevRequests.map((req) =>
            req.id === updatedRequest.id ? updatedRequest : req
          )
        );

        setRecentSparrings((prevSparrings) =>
          prevSparrings.map((sparring) =>
            sparring.id === updatedRequest.id ? updatedRequest : sparring
          )
        );

        toast.success("Game link and scores updated successfully!");
        setShowModal(false);
      } catch (error) {
        console.error("Error updating game link and score:", error);
        toast.error("Failed to update the sparring request. Please try again.");
      }
    }
  };

  const handleAcceptRequest = async (requestId: number) => {
    try {
      const request = pendingRequests.find((req) => req.id === requestId);
      if (request) {
        setSelectedRequest(request);

        await axios.post(`/sparring-requests/${requestId}/accept/`);

        await fetchOverlappingSlots(request);

        setPendingRequests((prevRequests) =>
          prevRequests.filter((req) => req.id !== requestId)
        );
        setToBeScheduledRequests((prevRequests) => [...prevRequests, request]);
        setShowProposeSlotsModal(true);
      }
    } catch (error) {
      console.error("Error accepting request:", error);
      toast.error("Failed to accept the sparring request. Please try again.");
    }
  };

  const handleProposeSlots = async () => {
    if (!selectedRequest || selectedSlots.length === 0) {
      toast.error("Please select at least one slot to propose.");
      return;
    }

    try {
      const slotsToSend = selectedSlots
        .map((slotId) => overlappingSlots.find((slot) => slot.id === slotId))
        .filter(
          (slot): slot is { id: string; start: string; end: string } => !!slot
        );

      if (slotsToSend.length === 0) {
        toast.error("No valid slots to propose.");
        return;
      }

      // Send proposed slots to the server
      await axios.post(
        `/sparring-requests/${selectedRequest.id}/propose_slots/`,
        {
          proposed_slots: slotsToSend,
        }
      );

      // Update the selected request with the proposed slots
      setSelectedRequest((prevRequest) => {
        if (!prevRequest) return prevRequest;

        const updatedRequest = {
          ...prevRequest,
          proposed_slots: slotsToSend,
        };

        // Update the request in the 'to be scheduled' list
        setToBeScheduledRequests((prevRequests) =>
          prevRequests.map((req) =>
            req.id === updatedRequest.id ? updatedRequest : req
          )
        );

        return updatedRequest;
      });

      setProposedSlots(slotsToSend);

      toast.success("Sparring slots proposed successfully!");
      setShowProposeSlotsModal(false);
    } catch (error) {
      console.error("Error proposing slots:", error);
      toast.error("Failed to propose sparring slots.");
    }
  };

  const handleDeclineRequest = async (requestId: number) => {
    try {
      await axios.post(`/sparring-requests/${requestId}/decline/`);
      setPendingRequests((prevRequests) =>
        prevRequests.filter((request) => request.id !== requestId)
      );
    } catch (error) {
      console.error("Error declining request:", error);
    }
  };

  const renderOverlappingSlots = () => {
    const slotsByDay = overlappingSlots.reduce((acc, slot) => {
      const day = new Date(slot.start).toLocaleDateString("en-GB", {
        day: "numeric",
        month: "long",
        year: "numeric",
      });

      if (!acc[day]) {
        acc[day] = [];
      }

      acc[day].push(slot);
      return acc;
    }, {} as { [key: string]: { id: string; start: string; end: string }[] });

    const sortedDays = Object.keys(slotsByDay).sort((a, b) => {
      return new Date(a).getTime() - new Date(b).getTime();
    });

    return (
      <div className="slot-grid">
        {sortedDays.map((day) => (
          <div key={day} className="slot-column">
            <h5 className="slot-day-title">{day}</h5>
            {slotsByDay[day]
              .sort(
                (a, b) =>
                  new Date(a.start).getTime() - new Date(b.start).getTime()
              )
              .map((slot) => (
                <div
                  key={slot.id}
                  className={`slot-card ${
                    selectedSlots.includes(slot.id) ? "selected" : ""
                  }`}
                  onClick={() => handleSlotSelection(slot.id)}
                >
                  <input
                    type="checkbox"
                    className="slot-checkbox"
                    checked={selectedSlots.includes(slot.id)}
                    onChange={() => handleSlotSelection(slot.id)}
                  />
                  <span className="slot-time">{`${formatTime(
                    slot.start
                  )} - ${formatTime(slot.end)}`}</span>
                </div>
              ))}
          </div>
        ))}
      </div>
    );
  };

  const handleSlotSelection = (slotId: string) => {
    if (selectedSlots.includes(slotId)) {
      setSelectedSlots((prevSelectedSlots) =>
        prevSelectedSlots.filter((id) => id !== slotId)
      );
    } else {
      setSelectedSlots((prevSelectedSlots) => [...prevSelectedSlots, slotId]);
    }
  };

  const handleConfirmSlots = async () => {
    if (!selectedRequest || selectedSlots.length === 0) {
      toast.error("Please select a slot.");
      return;
    }

    try {
      const selectedSlot = proposedSlots.find(
        (slot) => slot.id === selectedSlots[0]
      );

      await axios.post(
        `/sparring-requests/${selectedRequest.id}/select-slot/`,
        { selected_slot: selectedSlot }
      );

      setSelectedRequest((prevRequest) => {
        if (!prevRequest) return prevRequest;

        const updatedRequest = {
          ...prevRequest,
          selected_slot: selectedSlot,
        };

        // Mettre à jour les sparrings planifiés directement dans l'état
        setScheduledRequests((prevScheduledRequests) => [
          ...prevScheduledRequests,
          updatedRequest,
        ]);

        // Retirer le sparring de la liste "To Be Scheduled"
        setToBeScheduledRequests((prevToBeScheduled) =>
          prevToBeScheduled.filter(
            (request) => request.id !== updatedRequest.id
          )
        );

        // Appeler la fonction onRequestUpdate
        onRequestUpdate(updatedRequest);

        return updatedRequest;
      });

      toast.success("Sparring slot selected successfully!");
      setShowConfirmSlotModal(false);
      setShowModal(true);
    } catch (error) {
      console.error("Error selecting slot:", error);
      toast.error("Failed to select sparring slot.");
    }
  };

  const getProfilePhotoUrl = (photo: string | null) => {
    const baseUrl = process.env.REACT_APP_MEDIA_URL || "http://localhost:8000";
    return photo
      ? `${baseUrl}${photo}`
      : `${baseUrl}/media/images/profile/default.png`;
  };

  const getOpponent = (
    sparring: SparringRequest,
    currentUserId: number | null
  ) => {
    if (!currentUserId) {
      return null;
    }

    return sparring.requester.id === currentUserId
      ? sparring.offer.user
      : sparring.requester;
  };

  // Fonction de fermeture de la modal principale
  const handleCloseMainModal = () => {
    setShowModal(false);
    setSelectedRequest(null); // Réinitialiser selectedRequest
    setOverlappingSlots([]); // Réinitialiser overlappingSlots
    setSelectedSlots([]);
    setProposedSlots([]); // Réinitialiser proposedSlots
  };

  // Fonction de fermeture de la modal pour proposer des créneaux
  const handleCloseProposeSlotsModal = () => {
    setShowProposeSlotsModal(false);
    setSelectedRequest(null); // Réinitialiser selectedRequest
    setOverlappingSlots([]); // Réinitialiser overlappingSlots
    setSelectedSlots([]);
    setProposedSlots([]); // Réinitialiser proposedSlots
  };

  const handleCloseConfirmSlotModal = () => {
    if (
      selectedRequest &&
      !selectedRequest.selected_slot &&
      currentUserId === selectedRequest.offer.user.id // Vérifier que l'utilisateur est l'offer
    ) {
      setShowProposeSlotsModal(true);
    }

    setShowConfirmSlotModal(false);
    setSelectedRequest(null); // Réinitialiser selectedRequest
    setOverlappingSlots([]); // Réinitialiser overlappingSlots
    setProposedSlots([]); // Réinitialiser proposedSlots
    setSelectedSlots([]);
  };

  const renderProposedSlots = () => {
    const slotsByDay = proposedSlots.reduce((acc, slot) => {
      const day = new Date(slot.start).toLocaleDateString("en-GB", {
        day: "numeric",
        month: "long",
        year: "numeric",
      });

      if (!acc[day]) {
        acc[day] = [];
      }

      acc[day].push(slot);
      return acc;
    }, {} as { [key: string]: { id: string; start: string; end: string }[] });

    const sortedDays = Object.keys(slotsByDay).sort((a, b) => {
      return new Date(a).getTime() - new Date(b).getTime();
    });

    return (
      <div className="slot-grid">
        {sortedDays.map((day) => (
          <div key={day} className="slot-column">
            <h5 className="slot-day-title">{day}</h5>
            {slotsByDay[day]
              .sort(
                (a, b) =>
                  new Date(a.start).getTime() - new Date(b.start).getTime()
              )
              .map((slot) => (
                <div
                  key={slot.id}
                  className={`slot-card ${
                    selectedSlots.includes(slot.id) ? "selected" : ""
                  }`}
                  onClick={() => handleSlotSelection(slot.id)}
                >
                  <input
                    type="checkbox"
                    className="slot-checkbox"
                    checked={selectedSlots.includes(slot.id)}
                    onChange={() => handleSlotSelection(slot.id)}
                  />
                  <span className="slot-time">{`${formatTime(
                    slot.start
                  )} - ${formatTime(slot.end)}`}</span>
                </div>
              ))}
          </div>
        ))}
      </div>
    );
  };

  const renderModalContent = () => {
    if (!selectedRequest) return null;

    const opponent = getOpponent(selectedRequest, currentUserId);
    if (!opponent) {
      return null;
    }

    const isFreeUser = user?.subscription_status === "free";
    const isToBeScheduledOrScheduled =
      toBeScheduledRequests.some((req) => req.id === selectedRequest.id) ||
      scheduledRequests.some((req) => req.id === selectedRequest.id);

    const sparringDate = selectedRequest.selected_slot
      ? `on ${new Date(selectedRequest.selected_slot.start).toLocaleDateString(
          "en-GB",
          {
            day: "numeric",
            month: "long",
          }
        )}, ${formatTime(selectedRequest.selected_slot.start)} - ${formatTime(
          selectedRequest.selected_slot.end
        )}`
      : "Date : to define";

    // Check if there are proposed slots
    const hasProposedSlots = proposedSlots.length > 0;

    // Check if the request is pending
    const isPendingRequest =
      pendingRequests.some((req) => req.id === selectedRequest.id) ||
      sentPendingRequests.some((req) => req.id === selectedRequest.id);

    // Check if the other user hasn't proposed slots yet and display the message accordingly
    if (
      selectedRequest.status === "accepted" &&
      currentUserId === selectedRequest.requester.id &&
      (!selectedRequest.proposed_slots ||
        selectedRequest.proposed_slots.length === 0)
    ) {
      return (
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-12 text-center">
              <p>Waiting for your sparring partner to propose 3 slots.</p>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-3 text-center">
            <img
              src={getProfilePhotoUrl(opponent.photo)}
              alt="Profile"
              className="profile-photo-modal"
            />
          </div>
          <div className="col-9 text-left">
            <h2 className="mb-2">
              {opponent.first_name} {opponent.last_name}{" "}
              {opponent.is_elo_verified && (
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  style={{
                    color: "blue",
                    marginLeft: "8px",
                    fontSize: "1.5rem",
                  }}
                  title="Elo Verified"
                />
              )}
            </h2>
            <p
              style={{
                fontSize: "0.9rem",
                color: "#8d493a",
                marginTop: "-5px",
                textAlign: "center",
              }}
            >
              ({opponent.elo} ELO)
            </p>
            <p
              className="mb-1"
              style={{
                fontStyle: "italic",
                fontSize: "0.9rem",
                marginTop: "15px",
              }}
            >
              {sparringDate}
            </p>
          </div>
        </div>

        {hasProposedSlots && !selectedRequest.selected_slot && (
          <div className="proposed-slots-section mt-3">
            <hr />
            <h5>My Proposed Slots (Waiting for Response):</h5>
            <ul>
              {proposedSlots.map((slot) => (
                <li key={slot.id}>
                  {`${formatTime(slot.start)} - ${formatTime(slot.end)}`}
                </li>
              ))}
            </ul>
            <hr />
          </div>
        )}

        {/* Display the CTA for non-premium users */}
        {isFreeUser && (
          <div className="premium-cta mt-3 p-3 text-center">
            <p>
              Upgrade to <strong>Premium</strong> to get your games analyzed by
              top coaches!
            </p>
            <Button
              className="premium-button"
              onClick={() => {
                window.location.href = "/premium";
              }}
            >
              Become Premium Now
            </Button>
          </div>
        )}

        {/* Only display score and game link if it's not a pending request */}
        {!isPendingRequest && (
          <>
            <hr />
            <div
              className={`score-input-group mt-3 ${
                isToBeScheduledOrScheduled ? "disabled" : ""
              }`}
              title={
                isToBeScheduledOrScheduled
                  ? "You can only enter scores and game links after the sparring has passed."
                  : ""
              }
            >
              <span>
                {selectedRequest.requester.id === currentUserId
                  ? "You"
                  : opponent.first_name}
              </span>
              <input
                type="number"
                value={scoreInput.player1Score}
                onChange={(e) =>
                  setScoreInput({
                    ...scoreInput,
                    player1Score: e.target.value,
                  })
                }
                className="score-box"
                placeholder="Score"
                disabled={
                  isToBeScheduledOrScheduled ||
                  selectedRequest.requester_score !== null
                } // Disable if scheduled or score already set
              />
              <span className="score-separator">-</span>
              <input
                type="number"
                value={scoreInput.player2Score}
                onChange={(e) =>
                  setScoreInput({
                    ...scoreInput,
                    player2Score: e.target.value,
                  })
                }
                className="score-box"
                placeholder="Score"
                disabled={
                  isToBeScheduledOrScheduled ||
                  selectedRequest.offer_score !== null
                } // Disable if scheduled or score already set
              />
              <span>
                {selectedRequest.requester.id !== currentUserId
                  ? "You"
                  : opponent.first_name}
              </span>
            </div>

            {/* Display the text area only if the game_link is not yet defined */}
            {!selectedRequest.game_link && (
              <textarea
                value={gameLinkInput}
                onChange={(e) => setGameLinkInput(e.target.value)}
                className={`game-link-input ${
                  isToBeScheduledOrScheduled ? "disabled" : ""
                }`}
                placeholder="Enter game link or details"
                disabled={isToBeScheduledOrScheduled} // Disable if already scheduled or done
                title={
                  isToBeScheduledOrScheduled
                    ? "You can only enter game links after the sparring has passed."
                    : ""
                }
              />
            )}

            {/* Display non-clickable links if game_link is defined */}
            {selectedRequest.game_link && (
              <div
                className="game-links-container mt-3 p-3"
                style={{
                  backgroundColor: "#f0e4d7",
                  borderRadius: "8px",
                  border: "1px solid #d0b8a8",
                  padding: "15px",
                }}
              >
                <h5 style={{ color: "#8d493a", marginBottom: "10px" }}>
                  <FontAwesomeIcon
                    icon={faLink}
                    style={{ marginRight: "5px" }}
                  />
                  Game Links:
                </h5>
                <div
                  className="game-links-list"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  {selectedRequest.game_link ? (
                    selectedRequest.game_link
                      .split(",") // Split the links by comma
                      .map((link, index) => {
                        const formattedLink = link.trim().startsWith("http")
                          ? link.trim()
                          : `https://${link.trim()}`;

                        return (
                          <div key={index} style={{ marginBottom: "10px" }}>
                            <a
                              href={formattedLink}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                color: "#721c24",
                                textDecoration: "none",
                                fontSize: "0.95rem", // Slightly smaller font size
                                padding: "8px", // Reduced padding for a tighter look
                                backgroundColor: "#fff",
                                borderRadius: "6px",
                                border: "1px solid #d0b8a8",
                                display: "inline-block", // Ensures each link is on a new line
                                width: "100%", // Ensures the link takes up full width
                                wordBreak: "break-all",
                              }}
                            >
                              {formattedLink}
                            </a>
                          </div>
                        );
                      })
                  ) : (
                    <p style={{ color: "#6d4c3d" }}>No links available</p>
                  )}
                </div>
              </div>
            )}
          </>
        )}

        {!isFreeUser && (
          <>
            <hr />
            {selectedRequest.review_status === "in_progress" && (
              <div className="row mt-3">
                <div className="col-12 text-center">
                  <FontAwesomeIcon
                    icon={faHourglass}
                    className="rotate-animation"
                    size="2x"
                    style={{ color: "#8d493a" }}
                  />
                  <p className="mt-2">
                    One of the best coaches is reviewing your games! You will
                    receive an email when he's finished!
                  </p>
                </div>
              </div>
            )}
            {selectedRequest.review_status === "completed" && (
              <div className="row mt-3">
                <div className="col-12 text-center">
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    style={{ color: "#8d493a" }}
                    size="2x"
                  />
                  <p className="mt-2">
                    The coach has analyzed your games.{" "}
                    <a href="/weekly-summaries">Check the analysis here.</a>
                  </p>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  const renderTableRowsWithSkeleton = (
    requests: SparringRequest[],
    includeDate = false,
    includeActions = false
  ) => {
    if (loading) {
      return (
        <>
          {Array.from({ length: 1 }).map((_, index) => (
            <SkeletonLoader key={index} />
          ))}
        </>
      );
    }

    // Display the actual rows if data is available
    if (requests.length === 0) {
      return (
        <tr>
          <td colSpan={6} className="text-center">
            No sparring requests available.
          </td>
        </tr>
      );
    }

    return requests.map((request) => {
      const opponent = getOpponent(request, currentUserId);
      const isRequester = request.requester.id === currentUserId;
      const isWaitingForYouToConfirm =
        isRequester &&
        request.proposed_slots &&
        request.proposed_slots.length > 0 &&
        !request.selected_slot;
      const isWaitingForOpponent =
        !isRequester &&
        request.proposed_slots &&
        request.proposed_slots.length > 0 &&
        !request.selected_slot;

      return (
        <tr
          key={request.id}
          onClick={() => handleRowClick(request)}
          className="clickable-row"
        >
          <td>
            <img
              src={getProfilePhotoUrl(opponent?.photo || "")}
              alt="Profile"
              className="profile-photo"
            />
          </td>
          <td>
            <CountryFlag countryCode={opponent?.country || ""} />
          </td>
          <td>
            {/*
    {opponent?.first_name ?? "Unknown"} {opponent?.last_name ?? ""}
  */}
            {opponent?.username ?? "Unknown"}
            {opponent?.is_elo_verified && (
              <FontAwesomeIcon
                icon={faCheckCircle}
                style={{ color: "blue", marginLeft: "8px" }}
                title="Elo Verified"
              />
            )}
          </td>
          <td>{opponent?.elo} elo</td>
          {includeDate && (
            <td>
              {request.selected_slot
                ? `${formatDate(request.selected_slot.start)}`
                : "Date : to define"}
            </td>
          )}
          <td>
            {isWaitingForYouToConfirm && (
              <FontAwesomeIcon
                icon={faHandPointer}
                style={{ color: "green", marginLeft: "5px" }}
                title="It's your turn to confirm the slot"
              />
            )}
            {isWaitingForOpponent && (
              <FontAwesomeIcon
                icon={faHourglassHalf}
                style={{ color: "orange", marginLeft: "5px" }}
                title="Waiting for your opponent to confirm"
              />
            )}
          </td>
          {includeActions && (
            <td onClick={(e) => e.stopPropagation()}>
              <Button
                variant="success"
                size="sm"
                onClick={() => handleAcceptRequest(request.id)}
                className="mr-2"
              >
                <FontAwesomeIcon icon={faCheck} />
              </Button>
              <Button
                variant="danger"
                size="sm"
                onClick={() => handleDeclineRequest(request.id)}
              >
                <FontAwesomeIcon icon={faTimes} />
              </Button>
            </td>
          )}
        </tr>
      );
    });
  };

  const renderRecentSparrings = () => {
    if (recentSparrings.length === 0) {
      return (
        <tr>
          <td colSpan={4} className="text-center">
            No recent sparrings available.
          </td>
        </tr>
      );
    }
    return recentSparrings.map((sparring) => {
      const opponent = getOpponent(sparring, currentUserId);
      if (!opponent) return null;

      // Gérer les scores manquants
      const userScore =
        sparring.requester.id === currentUserId
          ? sparring.requester_score ?? "N/A"
          : sparring.offer_score ?? "N/A";
      const opponentScore =
        sparring.requester.id !== currentUserId
          ? sparring.requester_score ?? "N/A"
          : sparring.offer_score ?? "N/A";

      const isWin = userScore > opponentScore;
      const isDraw = userScore === opponentScore;
      const resultIcon = isWin
        ? faCheckCircle
        : isDraw
        ? faChessKing
        : faTimesCircle;

      const resultColor = isWin
        ? "text-success"
        : isDraw
        ? "text-warning"
        : "text-danger";

      return (
        <tr
          key={sparring.id} // Assure-toi que chaque sparring a un id unique
          onClick={() => handleRecentSparringClick(sparring)}
          className="clickable-row"
        >
          <td>
            <CountryFlag countryCode={opponent.country} />
          </td>
          <td>
            {opponent.first_name} {opponent.last_name}{" "}
            {opponent.is_elo_verified && (
              <FontAwesomeIcon
                icon={faCheckCircle}
                style={{ color: "blue", marginLeft: "8px" }}
                title="Elo Verified"
              />
            )}
          </td>
          <td className="d-flex flex-column align-items-center">
            {userScore} - {opponentScore}
            <FontAwesomeIcon
              icon={resultIcon}
              className={`mt-2 ${resultColor}`}
            />
          </td>
          <td>
            {sparring.selected_slot
              ? formatDate(sparring.selected_slot.start)
              : "N/A"}
          </td>
        </tr>
      );
    });
  };

  const renderDoneSparrings = () => {
    return doneRequests.map((sparring) => {
      const opponent = getOpponent(sparring, currentUserId);
      if (!opponent) return null;

      const userScore =
        sparring.requester.id === currentUserId
          ? sparring.requester_score
          : sparring.offer_score;
      const opponentScore =
        sparring.requester.id !== currentUserId
          ? sparring.requester_score
          : sparring.offer_score;

      return (
        <tr
          key={sparring.id}
          onClick={() => handleRowClick(sparring)}
          className="clickable-row"
        >
          <td>
            <CountryFlag countryCode={opponent.country || ""} />
          </td>
          <td>
            {opponent.first_name} {opponent.last_name} - {opponent.elo} elo{" "}
            {opponent.is_elo_verified && (
              <FontAwesomeIcon
                icon={faCheckCircle}
                style={{ color: "blue", marginLeft: "8px" }}
                title="Elo Verified"
              />
            )}
          </td>

          <td>
            {userScore !== null && opponentScore !== null
              ? `${userScore} - ${opponentScore}`
              : "-"}
          </td>
          <td>
            {sparring.selected_slot
              ? `${formatDate(sparring.selected_slot.start)}`
              : "Date : to define"}
          </td>
        </tr>
      );
    });
  };

  return (
    <div className="container-fluid mt-5 my-sparrings">
      <div className="row">
        <div className="col-lg-6 column-spacing">
          <h3 className="highlight-title">
            <FontAwesomeIcon icon={faHourglassHalf} className="text-warning" />{" "}
            Pending Sparrings
          </h3>

          {/* Always display Received Requests section */}
          <div>
            <h4>
              <FontAwesomeIcon icon={faArrowDown} className="text-primary" />{" "}
              Received Requests
            </h4>
            <table className="table table-bordered">
              <tbody>
                {loading ? (
                  <>
                    {Array.from({ length: 1 }).map((_, index) => (
                      <SkeletonLoader key={index} />
                    ))}
                  </>
                ) : pendingRequests.length > 0 ? (
                  renderTableRowsWithSkeleton(pendingRequests, false, true)
                ) : (
                  <tr>
                    <td colSpan={6} className="text-center">
                      No received requests.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {/* Always display Sent Requests section */}
          <div>
            <h4>
              <FontAwesomeIcon icon={faArrowUp} className="text-primary" /> Sent
              Requests
            </h4>
            <table className="table table-bordered">
              <tbody>
                {loading ? (
                  // Render 5 SkeletonLoader components when loading is true
                  Array.from({ length: 1 }).map((_, index) => (
                    <SkeletonLoader key={index} />
                  ))
                ) : sentPendingRequests.length > 0 ? (
                  renderTableRowsWithSkeleton(sentPendingRequests, false)
                ) : (
                  <tr>
                    <td colSpan={6} className="text-center">
                      No sent requests.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-lg-6 column-spacing">
          <h3 className="highlight-title">
            <FontAwesomeIcon icon={faThumbtack} className="text-color" />{" "}
            Accepted Sparrings
          </h3>
          <h4>
            <FontAwesomeIcon icon={faClock} className="icon-to-be-scheduled" />{" "}
            To Be Scheduled
          </h4>
          <table className="table table-bordered">
            <tbody>
              {loading ? (
                // Render 5 SkeletonLoader components when loading is true
                Array.from({ length: 1 }).map((_, index) => (
                  <SkeletonLoader key={index} />
                ))
              ) : toBeScheduledRequests.length > 0 ? (
                renderTableRowsWithSkeleton(toBeScheduledRequests, false, false)
              ) : (
                <tr>
                  <td colSpan={6} className="text-center">
                    No sparrings to be scheduled.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <h4>
            <FontAwesomeIcon
              icon={faCalendarCheck}
              className="icon-scheduled"
            />{" "}
            Scheduled
          </h4>
          <table className="table table-bordered">
            <tbody>
              {loading ? (
                // Render 5 SkeletonLoader components when loading is true
                Array.from({ length: 1 }).map((_, index) => (
                  <SkeletonLoader key={index} />
                ))
              ) : scheduledRequests.length > 0 ? (
                renderTableRowsWithSkeleton(scheduledRequests, true, false)
              ) : (
                <tr>
                  <td colSpan={6} className="text-center">
                    No scheduled sparrings.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <div className="row mt-5">
        <div className="col-lg-12">
          <h3 className="highlight-title">Done Sparrings</h3>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Country</th>
                <th>Opponent</th>
                <th>Score</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                // Render 5 SkeletonLoader components when loading is true
                Array.from({ length: 1 }).map((_, index) => (
                  <SkeletonLoader key={index} />
                ))
              ) : doneRequests.length > 0 ? (
                renderDoneSparrings()
              ) : (
                <tr>
                  <td colSpan={6} className="text-center">
                    No done sparrings available.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <div className="row mt-5">
        <div className="col-lg-12">
          <h3 className="highlight-title">Recent Sparrings</h3>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Country</th>
                <th>Opponent</th>
                <th>Score</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                // Render 5 SkeletonLoader components when loading is true
                Array.from({ length: 1 }).map((_, index) => (
                  <SkeletonLoader key={index} />
                ))
              ) : recentSparrings.length > 0 ? (
                renderRecentSparrings()
              ) : (
                <tr>
                  <td colSpan={4} className="text-center">
                    No recent sparrings available.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {selectedRequest && (
        <Modal
          show={showModal}
          onHide={handleCloseMainModal}
          centered
          dialogClassName="modal-md"
        >
          <Modal.Header closeButton>
            <Modal.Title>Sparring Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>{renderModalContent()}</Modal.Body>
          <Modal.Footer>
            {/* Affiche le bouton "Save" uniquement si les scores ou le game link ne sont pas encore entrés
      ET si la date du sparring est passée */}
            {selectedRequest &&
              selectedRequest.selected_slot &&
              new Date(selectedRequest.selected_slot.end) < new Date() &&
              (selectedRequest.requester_score === null ||
                selectedRequest.offer_score === null ||
                !selectedRequest.game_link) && (
                <Button variant="primary" onClick={handleSave}>
                  Save
                </Button>
              )}
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      <Modal
        show={showProposeSlotsModal}
        onHide={handleCloseProposeSlotsModal}
        centered
        dialogClassName="modal-xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Propose Slots</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Propose up to 3 slots to your partner! They will need to choose one.
          </p>
          <ul className="list-group">{renderOverlappingSlots()}</ul>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={handleProposeSlots}>
            Propose Slots
          </Button>
          <Button
            variant="secondary"
            onClick={() => setShowProposeSlotsModal(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showConfirmSlotModal}
        onHide={handleCloseConfirmSlotModal}
        centered
        dialogClassName="modal-md"
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Slot</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Here are the slots your opponent proposed. Choose one!</p>
          <ul className="list-group">{renderProposedSlots()}</ul>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={handleConfirmSlots}>
            Confirm Slot
          </Button>
          <Button
            variant="secondary"
            onClick={() => setShowConfirmSlotModal(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default MySparrings;
