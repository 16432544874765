import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChessKing,
  faChessQueen,
  faChessRook,
  faChessBishop,
  faChessKnight,
  faChessPawn,
} from "@fortawesome/free-solid-svg-icons";
import "../css/BackgroundFloatingPieces.css";

const chessIcons = [
  faChessKing,
  faChessQueen,
  faChessRook,
  faChessBishop,
  faChessKnight,
  faChessPawn,
];

const FloatingChessPieces: React.FC = () => {
  const [pieces, setPieces] = useState<
    {
      id: number;
      icon: any;
      left: number;
      top: number;
      speed: number;
      direction: number;
    }[]
  >([]);

  useEffect(() => {
    const generatePieces = () => {
      const newPieces = [];
      for (let i = 0; i < 10; i++) {
        const isLeftSide = i < 5; // First 5 pieces on the left side, last 5 on the right side
        const icon = chessIcons[Math.floor(Math.random() * chessIcons.length)];
        const left = isLeftSide
          ? Math.random() * 20 // Random position from 0 to 30% for left side
          : 80 + Math.random() * 20; // Random position from 70 to 100% for right side
        const top = 25 + Math.random() * 50; // Centered between 25% and 75% of the page height
        const speed = 0.1 + Math.random() * 0.2; // Reduced speed between 0.1 and 0.3
        const direction = Math.random() < 0.5 ? 1 : -1; // Random direction for the movement

        newPieces.push({ id: i, icon, left, top, speed, direction });
      }
      setPieces(newPieces);
    };

    generatePieces();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setPieces((prevPieces) =>
        prevPieces.map((piece) => ({
          ...piece,
          top:
            piece.top +
            Math.sin(Date.now() / 2000 + piece.id) * // Adjust the divisor to slow down the oscillation
              piece.speed *
              piece.direction, // Smooth oscillating motion with reduced speed
        }))
      );
    }, 100); // Larger interval for smoother and slower animation

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {pieces.map((piece) => (
        <FontAwesomeIcon
          key={piece.id}
          icon={piece.icon}
          className="floating-piece"
          style={{
            position: "absolute",
            left: `${piece.left}%`,
            top: `${piece.top}%`,
            color: "#8d493a",
            fontSize: "2rem",
            transition: "top 0.5s ease", // Slower transition for smoother movement
          }}
        />
      ))}
    </>
  );
};

export default FloatingChessPieces;
