import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import axiosConfig from "../axiosConfig";
import { isAxiosError } from "../utils/axiosErrorUtils";
import { useAuth } from "../AuthContext";
import Cookies from "js-cookie";
import "../css/AboutYou.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { UserFormData } from "../types";

const defaultProfilePic = "/media/images/profile/default.png";

const getProfilePhotoUrl = (photo: string | null) => {
  if (photo) {
    if (
      photo.startsWith("http") ||
      photo.startsWith("https") ||
      photo.startsWith("blob:")
    ) {
      return photo;
    }
    return `${process.env.REACT_APP_MEDIA_URL}${photo}`;
  }
  return `${process.env.REACT_APP_MEDIA_URL}${defaultProfilePic}`;
};

const AboutYou: React.FC = () => {
  const navigate = useNavigate();
  const { setUser } = useAuth();
  const [formData, setFormData] = useState<UserFormData>({
    elo: "",
    first_name: "",
    last_name: "",
    photo: null,
    photo_url: getProfilePhotoUrl(null),
    username: "",
  });
  const [error, setError] = useState<string | null>(null);
  const [step, setStep] = useState<number>(1);
  const [backgroundImage, setBackgroundImage] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    setBackgroundImage("media/images/bg_login.svg");

    document.body.classList.add("about-you-page");

    return () => {
      document.body.classList.remove("about-you-page");
    };
  }, []);

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setFormData({ ...formData, photo: file });
      const previewUrl = URL.createObjectURL(file);
      setFormData((prevData) => ({
        ...prevData,
        photo_url: previewUrl,
      }));
    }
  };

  const handleProfileImageClick = () => {
    fileInputRef.current?.click();
  };

  const isStep1Valid = () => {
    return (
      formData.first_name.trim() !== "" && formData.last_name.trim() !== ""
    );
  };

  const isStep2Valid = () => {
    return formData.username.trim() !== "" && formData.elo.trim() !== "";
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const formDataToSend = new FormData();
      Object.keys(formData).forEach((key) => {
        const value = formData[key as keyof UserFormData];
        if (value !== null && key !== "photo_url") {
          formDataToSend.append(key, value as any);
        }
      });

      const response = await axiosConfig.put("/profile/", formDataToSend, {
        headers: {
          "X-CSRFToken": Cookies.get("csrftoken")!,
          "Content-Type": "multipart/form-data",
        },
      });

      const updatedUserData = response.data;
      setFormData((prevData) => ({
        ...prevData,
        ...updatedUserData,
        photo_url: getProfilePhotoUrl(updatedUserData.photo),
      }));

      setUser({
        username: formData.username,
      });

      navigate("/dashboard");
    } catch (error: any) {
      if (isAxiosError(error) && error.response) {
        const data = error.response.data as any;
        setError(data.detail || "Failed to update profile");
      } else {
        setError("Failed to update profile");
      }
      console.error("Profile update failed:", error);
    }
  };

  const handleNext = () => {
    if ((step === 1 && isStep1Valid()) || (step === 2 && isStep2Valid())) {
      setStep(step + 1);
    } else {
      setError("Please fill out all required fields before proceeding.");
    }
  };

  const handlePrevious = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  return (
    <div id="about-you-container" className="container">
      <div className="about-you-left">
        <div
          className="background-image"
          style={{
            backgroundImage: `url(${process.env.REACT_APP_MEDIA_URL}/${backgroundImage})`,
          }}
        ></div>
      </div>
      <div className="about-you-right">
        <div className="card shadow custom-card">
          <div className="card-body">
            <h2>Let's make your profile shine!</h2>
            {error && <div className="alert alert-danger">{error}</div>}
            <Form onSubmit={handleSubmit} encType="multipart/form-data">
              {step === 1 && (
                <>
                  <div
                    className="profile-image-wrapper"
                    onClick={handleProfileImageClick}
                  >
                    <img
                      src={getProfilePhotoUrl(formData.photo_url)}
                      alt="Profile"
                      className="profile-image"
                    />
                    <div className="edit-overlay">
                      <FontAwesomeIcon icon={faPen} className="edit-icon" />
                    </div>
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                  </div>
                  <Form.Group controlId="first_name">
                    <Form.Label>
                      First Name <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="first_name"
                      value={formData.first_name}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="last_name">
                    <Form.Label>
                      Last Name <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="last_name"
                      value={formData.last_name}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Button onClick={handleNext} variant="primary">
                    Next
                  </Button>
                </>
              )}

              {step === 2 && (
                <>
                  <Form.Group controlId="username">
                    <Form.Label>
                      Username <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="username"
                      value={formData.username}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="elo">
                    <Form.Label>
                      Elo <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="number"
                      name="elo"
                      value={formData.elo}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <div className="d-flex justify-content-between next-back">
                    <Button onClick={handlePrevious} variant="secondary">
                      Previous
                    </Button>
                    <Button variant="primary" type="submit">
                      Save
                    </Button>
                  </div>
                </>
              )}
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutYou;
