// LoadingSpinner.tsx
import React from "react";
import { Spinner } from "react-bootstrap";
import "../css/LoadingSpinner.css"; // Importer le fichier CSS

const LoadingSpinner: React.FC<{
  size?: "sm" | "lg"; // Ajoutez "lg" pour un spinner plus grand
  spinnerColor?: string;
  backgroundColor?: string;
}> = ({
  size = "sm",
  spinnerColor = "#8d493a", // Couleur par défaut du spinner
  backgroundColor = "#f8ede3", // Couleur par défaut du fond
}) => {
  // Calcul de la taille du spinner
  const spinnerSize = size === "lg" ? "3rem" : "1.5rem"; // Taille ajustable

  return (
    <div
      className="loading-spinner-container"
      style={{
        backgroundColor,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
      }}
    >
      <Spinner
        animation="border"
        style={{
          width: spinnerSize,
          height: spinnerSize,
          borderWidth: size === "lg" ? "0.3rem" : "0.2rem", // Épaissir la bordure pour le spinner plus grand
          borderColor: spinnerColor, // Appliquer la couleur du spinner
          borderRightColor: "transparent", // Ajoutez cet effet pour faire tourner le spinner
        }}
      />
    </div>
  );
};

export default LoadingSpinner;
