import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "../axiosConfig";
import { useAuth } from "../AuthContext";
import { Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import "../css/ConfirmEmail.css";

const ConfirmEmail: React.FC = () => {
  const { token } = useParams<{ token: string }>();
  const { updateVerifiedStatus, refetchProfile } = useAuth();
  const [status, setStatus] = useState<
    "loading" | "success" | "error" | "alreadyConfirmed"
  >("loading");
  const [hasFetched, setHasFetched] = useState(false);

  useEffect(() => {
    if (!hasFetched && token) {
      setHasFetched(true);
      axios
        .get(`/confirm-email/${token}/`)
        .then((response) => {
          // Check if the response indicates that the email was already confirmed
          if (response.data.error === "Email already confirmed") {
            setStatus("alreadyConfirmed");
          } else {
            updateVerifiedStatus();
            refetchProfile();
            setStatus("success");
          }
        })
        .catch((_error) => {
          setStatus("error");
        });
    }
  }, [token, hasFetched, updateVerifiedStatus, refetchProfile]);

  return (
    <div className="confirm-email-page">
      <div className="confirmation-container">
        {status === "loading" && (
          <div>
            <Spinner
              animation="border"
              role="status"
              className="loading-spinner"
            >
              <span className="sr-only">Loading...</span>
            </Spinner>
            <h2>Email Verification in Progress</h2>
            <p>We are verifying your email address. Please wait a moment...</p>
          </div>
        )}
        {status === "success" && (
          <div className="success">
            <FontAwesomeIcon
              icon={faCheckCircle}
              size="5x"
              className="success-icon"
            />
            <h2>Congratulations!</h2>
            <p>Your email has been successfully confirmed.</p>
            <p>You can now enjoy all the features of our platform.</p>
          </div>
        )}
        {status === "alreadyConfirmed" && (
          <div className="info">
            <FontAwesomeIcon
              icon={faInfoCircle}
              size="5x"
              className="info-icon"
            />
            <h2>Email Already Confirmed</h2>
            <p>
              Your email has already been confirmed. You can now log in and use
              the platform.
            </p>
          </div>
        )}
        {status === "error" && (
          <div className="error">
            <FontAwesomeIcon
              icon={faTimesCircle}
              size="5x"
              className="error-icon"
            />
            <h2>Email Verification Failed</h2>
            <p>
              We couldn't verify your email. The link might have expired or is
              invalid.
            </p>
            <p>
              If you believe this is a mistake, please request a new
              confirmation link.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ConfirmEmail;
