import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Dropdown, Button } from "react-bootstrap";
import axios from "../axiosConfig";
import { isAxiosError } from "../utils/axiosErrorUtils";
import { useAuth } from "../AuthContext";
import CountryFlag from "../components/CountryFlag";
import { toast } from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "react-bootstrap";
import { Spinner } from "react-bootstrap";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import {
  faPen,
  faLink,
  faUser,
  faHouse,
} from "@fortawesome/free-solid-svg-icons";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import AvailabilityScheduler from "../components/AvailabilityScheduler";
import CountrySelector from "../components/CountrySelector";

import "../css/Profile.css";

ChartJS.register(ArcElement, Tooltip, Legend);

const defaultProfilePic = "/media/images/profile/default.png";

const getProfilePhotoUrl = (photo: string | null) => {
  if (photo) {
    if (photo.startsWith("http") || photo.startsWith("https")) {
      return photo;
    }
    return `${process.env.REACT_APP_MEDIA_URL}${photo}`;
  }
  const defaultProfileImageUrl = process.env.REACT_APP_MEDIA_URL
    ? `${process.env.REACT_APP_MEDIA_URL}media/images/profile/default.png`
    : "http://localhost:8000/media/images/profile/default.png";

  return defaultProfileImageUrl;
};

const getNoSparringsImagePath = () => {
  const apiUrl = process.env.REACT_APP_MEDIA_URL || "http://localhost:8000";
  return `${apiUrl}/media/images/no-sparring.png`;
};

const Profile: React.FC = () => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  const [userData, setUserData] = useState({
    first_name: "",
    last_name: "",
    username: "",
    about_me: "",
    elo: "",
    fide_link: "",
    country: "",
    club: "",
    photo: null as File | null,
    photo_url: defaultProfilePic,
    is_elo_verified: false,
  });

  const [previewPhoto, setPreviewPhoto] = useState<string>(defaultProfilePic);
  const [selectedPeriod, setSelectedPeriod] = useState<string>("last_week");
  const [stats, setStats] = useState({
    total_sparrings: 0,
    wins: 0,
    losses: 0,
    draws: 0,
    average_opponent_elo: 0,
    win_percentage: 0,
    average_elo_wins: 0,
    average_elo_losses: 0,
  });

  const [chartData, setChartData] = useState<any>(null);
  const [isEditingProfile, setIsEditingProfile] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [showCoinsModal, setShowCoinsModal] = useState(false);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    } else {
      const fetchUserData = async () => {
        try {
          const result = await axios.get("/profile/");
          setUserData(result.data);
          setPreviewPhoto(getProfilePhotoUrl(result.data.photo_url));
        } catch (error: unknown) {
          if (isAxiosError(error)) {
            console.error("Error fetching user data:", error);
            if (error.response && error.response.status === 403) {
              console.error("Authentication error. Please check your token.");
            }
          } else {
            console.error("An unexpected error occurred:", error);
          }
        }
      };
      fetchUserData();
    }

    document.body.classList.add("profile-page");

    return () => {
      document.body.classList.remove("profile-page");
    };
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const result = await axios.get(
          `/sparring-statistics/?period=${selectedPeriod}`
        );
        setStats(result.data[selectedPeriod]);
      } catch (error: unknown) {
        if (isAxiosError(error)) {
          console.error("Error fetching stats:", error);
        } else {
          console.error("An unexpected error occurred:", error);
        }
      }
    };
    fetchStats();
  }, [selectedPeriod]);

  // Nouvelle utilisation de useEffect pour mettre à jour les données du graphique
  useEffect(() => {
    setChartData({
      labels: ["Wins", "Losses", "Draws"],
      datasets: [
        {
          data: [stats.wins, stats.losses, stats.draws],
          backgroundColor: ["#4caf50", "#f44336", "#ffeb3b"],
          hoverBackgroundColor: ["#388e3c", "#d32f2f", "#fbc02d"],
        },
      ],
    });
  }, [stats]); // Mettre à jour lorsque les statistiques changent

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const handleSaveProfile = async () => {
    try {
      setIsVerifying(true); // Démarre le chargement

      // Formater les données pour la requête
      const formData = new FormData();
      formData.append("about_me", userData.about_me || "");
      formData.append("elo", userData.elo || "");
      formData.append("country", userData.country || "");
      formData.append("fide_link", userData.fide_link || "");
      formData.append("club", userData.club || "");

      let updatedUserData;

      if (userData.elo && userData.fide_link) {
        const response = await axios.put("/profile/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        updatedUserData = response.data;

        // Mets à jour les informations utilisateur
        setUserData(updatedUserData);

        if (updatedUserData.is_elo_verified) {
          toast.success("Elo verified successfully!");
        } else {
          toast.error("Failed to verify Elo. Please check your FIDE link.");
        }
      } else {
        // Si pas d'elo ou de lien FIDE, pas de vérification
        const response = await axios.put("/profile/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        updatedUserData = response.data;
        setUserData(updatedUserData);
      }

      const coinsResponse = await axios.post("/profile/award-coins/");
      if (coinsResponse.status === 200) {
        if (coinsResponse.data.coins_awarded) {
          toast.success(coinsResponse.data.message); // Afficher un message de succès
          setShowCoinsModal(false); // Fermer la modale s'il n'y a plus besoin de donner des coins
        } else {
          toast.success(coinsResponse.data.message); // Afficher un message informatif
          if (coinsResponse.data.display_modal) {
            setShowCoinsModal(true); // Afficher la modale si tous les champs ne sont pas remplis
          }
        }
      }

      setIsEditingProfile(false);
    } catch (error) {
      console.error("Error updating profile:", error);
      toast.error("Failed to update profile");
    } finally {
      setIsVerifying(false); // Arrête le chargement
    }
  };

  const handlePeriodChange = (eventKey: string | null) => {
    if (eventKey) {
      setSelectedPeriod(eventKey);
    }
  };

  const handlePhotoChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const previewUrl = URL.createObjectURL(file);
      setPreviewPhoto(previewUrl);

      try {
        const formData = new FormData();
        formData.append("photo", file);

        const response = await axios.put("/profile/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        const updatedUserData = response.data;
        setUserData(updatedUserData);
        toast.success("Profile photo updated successfully!");
      } catch (error) {
        console.error("Error updating profile photo:", error);
        toast.error("Failed to update profile photo");
      }
    }
  };

  const triggerFileInputClick = () => {
    document.getElementById("photo-input")?.click();
  };

  const noSparringsImagePath = getNoSparringsImagePath();

  const options = {
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context: any) {
            let label = context.label || "";

            if (label) {
              label += ": ";
            }

            if (context.raw !== null) {
              label += context.raw;
            }

            if (context.label === "Wins") {
              label += ` (Avg ELO: ${stats.average_elo_wins})`;
            } else if (context.label === "Losses") {
              label += ` (Avg ELO: ${stats.average_elo_losses})`;
            }

            return label;
          },
        },
      },
    },
  };

  return (
    <div className="profile-section">
      <div className="container mt-5">
        <div className="row">
          <div className="col-lg-4 col-md-6 d-flex">
            <div className="card shadow-sm flex-fill">
              <div className="card-body text-center">
                <div className="profile-photo" onClick={triggerFileInputClick}>
                  <img
                    src={previewPhoto}
                    alt="Profile"
                    className="rounded-circle"
                  />
                  <div className="edit-overlay">
                    <FontAwesomeIcon icon={faPen} className="edit-icon" />
                  </div>
                  <input
                    type="file"
                    id="photo-input"
                    style={{ display: "none" }}
                    onChange={handlePhotoChange}
                  />
                </div>
                <h3 className="mb-0 font-weight-bold">
                  {`${userData.first_name} ${userData.last_name}`}
                </h3>
                <p className="text-muted">
                  {userData.username} {userData.elo && `| Elo: ${userData.elo}`}
                  {userData.is_elo_verified && (
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      style={{ color: "blue", marginLeft: "8px" }}
                      title="Elo Verified"
                    />
                  )}
                </p>
                <div className="country-flag">
                  {isEditingProfile ? (
                    <CountrySelector
                      onChange={(value) =>
                        setUserData({ ...userData, country: value })
                      }
                      defaultValue={userData.country}
                    />
                  ) : userData.country ? (
                    <CountryFlag countryCode={userData.country} />
                  ) : (
                    <span></span>
                  )}
                </div>

                <hr className="profile-divider" />
                {isEditingProfile ? (
                  <div className="form-group profile-form edit-mode mt-3">
                    <label htmlFor="about_me">Bio</label>
                    <textarea
                      className="form-control edit-mode"
                      id="about_me"
                      name="about_me"
                      value={userData.about_me}
                      onChange={handleChange}
                    />
                    <label htmlFor="elo" className="mt-2">
                      Elo
                    </label>
                    <input
                      type="number"
                      className="form-control edit-mode"
                      id="elo"
                      name="elo"
                      value={userData.elo}
                      onChange={handleChange}
                    />
                    <label htmlFor="fide_link" className="mt-2">
                      FIDE Link
                    </label>
                    <input
                      type="url"
                      className="form-control edit-mode"
                      id="fide_link"
                      name="fide_link"
                      value={userData.fide_link}
                      onChange={handleChange}
                    />
                    <label htmlFor="club" className="mt-2">
                      Club
                    </label>
                    <input
                      type="text"
                      className="form-control edit-mode"
                      id="club"
                      name="club"
                      value={userData.club}
                      onChange={handleChange}
                    />
                    <Button
                      variant="primary"
                      className="btn-block mt-2"
                      onClick={handleSaveProfile}
                      disabled={isVerifying}
                    >
                      {isVerifying ? (
                        <>
                          <Spinner
                            animation="border"
                            size="sm"
                            className="mr-2"
                          />
                          Verifying your profile...
                        </>
                      ) : (
                        "Save Profile"
                      )}
                    </Button>
                  </div>
                ) : (
                  <div className="form-group profile-form">
                    <div className="about-me mt-3">
                      <FontAwesomeIcon
                        icon={faUser}
                        className="about-me-icon"
                      />
                      <p>{userData.about_me || "No information"}</p>
                    </div>
                    <div className="fide-link mt-3">
                      <FontAwesomeIcon
                        icon={faLink}
                        className="fide-link-icon"
                      />
                      <a
                        href={userData.fide_link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {userData.fide_link || "No information"}
                      </a>
                    </div>
                    <div className="club-info mt-3">
                      <FontAwesomeIcon icon={faHouse} className="club-icon" />
                      <p>{userData.club || "No information"}</p>
                    </div>
                  </div>
                )}
              </div>
              {!isEditingProfile && (
                <Button
                  variant="primary"
                  className="btn-block mt-3"
                  onClick={() => setIsEditingProfile(true)}
                >
                  Edit Profile
                </Button>
              )}
            </div>
          </div>
          <div className="col-lg-8 col-md-6 mt-4 mt-md-0">
            <div className="card">
              <div className="card-header bg-primary text-white">
                <h4 className="mb-0">Statistics</h4>
              </div>
              <Row className="mb-4 mt-4">
                <Col className="text-center">
                  <Dropdown onSelect={handlePeriodChange}>
                    <Dropdown.Toggle variant="primary" id="dropdown-basic">
                      {selectedPeriod === "last_week"
                        ? "Last Week"
                        : selectedPeriod === "last_month"
                        ? "Last Month"
                        : "Last Year"}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item eventKey="last_week">
                        Last Week
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="last_month">
                        Last Month
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="last_year">
                        Last Year
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              </Row>

              <div
                className="card-body d-flex align-items-center justify-content-center"
                style={{ height: "400px", marginTop: "20px" }}
              >
                {chartData && stats.total_sparrings > 0 ? (
                  <Pie data={chartData} options={options} />
                ) : (
                  <div className="text-center">
                    <img
                      src={noSparringsImagePath}
                      alt="No sparrings available"
                      className="img-fluid no-sparring"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Ajoutez la section pour les disponibilités */}
        <div className="availability-section mt-5">
          <h3>My Availability</h3>
          <AvailabilityScheduler />
        </div>
        <Modal
          show={showCoinsModal}
          onHide={() => setShowCoinsModal(false)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Earn Coins by Completing Your Profile!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Complete your profile to earn coins:</p>
            <ul>
              <li>{userData.about_me ? "✔️" : "❌"} Fill in your bio</li>
              <li>{userData.is_elo_verified ? "✔️" : "❌"} Verify your ELO</li>
              <li>{userData.club ? "✔️" : "❌"} Add your club</li>
            </ul>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => setShowCoinsModal(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default Profile;
